import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import TableContainer from "../../components/Common/TableContainer";
import { AppContext } from "../../context/AppProvider";
import withRouterHook from "../../withRouterHook";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { LoaderComponent } from "../../components/LoaderComponent";
import { Layout } from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import { SubscriptionPayoutStatus } from "../../lib/drivers/dto/enums/SubscriptionPayoutStatus";

export type EarnsPageProps = {};

type EarnsPageState = {
  isLoading: boolean;
  payoutData: {
    status: string;
    network: string;
    address: string;
    datePayment?: string;
  }[];
};

class EarnsPage extends React.Component<EarnsPageProps, EarnsPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: EarnsPageState = {
    isLoading: true,
    payoutData: [],
  };

  async componentDidMount() {
    try {
      const response = await PortCentralServer.Repo.client.getUserPayouts();

      const payoutData = response.data.map((payout: any) => ({
        status: payout.status,
        network: payout.network,
        address: payout.address,
        payoutAmount: payout.payoutAmount,
        datePayment: new Date(payout.datePayment).toLocaleString(),
      }));

      this.setState({ payoutData });
    } catch (error) {
      console.error("Error fetching payout data:", error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  getStatusClass(status: string) {
    switch (status) {
      case SubscriptionPayoutStatus.PENDING:
        return "text-warning";
      case SubscriptionPayoutStatus.PROCESSING:
        return "text-primary";
      case SubscriptionPayoutStatus.COMPLETED:
        return "text-success";
      case SubscriptionPayoutStatus.UNDER_REVIEW:
        return "text-danger";
      case SubscriptionPayoutStatus.NOT_SET:
      default:
        return "text-muted";
    }
  }

  render() {
    const columns = [
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }: { value: string }) => (
          <span className={this.getStatusClass(value)}>
            {value || "Unknown Status"}
          </span>
        ),
      },
      { Header: "Network", accessor: "network" },
      { Header: "Address", accessor: "address" },
      {
        Header: "Payout Amount (USDT)",
        accessor: "payoutAmount",
        Cell: ({ value }: { value: number }) => (
          value
        ),
      },
      { Header: "Date of Payment", accessor: "datePayment" },
    ];

    return (
      <Layout>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Earns" breadcrumbItem="History" />
            <Row>
              <Col xl="12">
                {this.state.isLoading ? (
                  <LoaderComponent />
                ) : (
                  <Card>
                    <CardBody>
                      <div className="mb-4 h4 card-title">Payout History</div>
                      <TableContainer
                        columns={columns}
                        data={this.state.payoutData}
                        isGlobalFilter={false}
                        isAddOptions={false}
                        customPageSize={10}
                      />
                    </CardBody>
                  </Card>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouterHook(EarnsPage);
