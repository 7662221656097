import React from "react";
import { useLocation } from "react-router-dom";
import { CardBody, Col, Row } from "reactstrap";
import { LoaderComponent } from "../../components/LoaderComponent";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import { BaseFilter } from "../../lib/core/filters/BaseFilter";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { SubscriptionStatus } from "../../lib/drivers/dto/enums/SubscriptionStatus";
import withRouterHook from "../../withRouterHook";
import PagerComponent from "../../components/PagerComponent";
import moment from "moment";
import { ViewUserSubscriptionProgressInfoDto } from "../../lib/drivers/dto/subscriptions/ViewUserSubscriptionProgressInfoDto";
import ApexRadial from "../../components/Common/ApexRadial";
import { BaseModal } from "../../components/modals/BaseModal";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

export type AccountsPageProps = {
  location?: ReturnType<typeof useLocation>;
};

type Filter = BaseFilter & {
  fundTradingProgramId?: string;
  status?: SubscriptionStatus;
};

type AccountsPageState = {
  isLoading: boolean;
  list: ViewUserSubscriptionProgressInfoDto[];
  filter: Filter;
  isActivationModalOpen: boolean;
  isCancelModalOpen: boolean;
  isCredentialsModalOpen: boolean;
  isClaimModalOpen: boolean;
  subscriptionToActivate: string;
  subscriptionToCancel: string;
  subscriptionToPayout: string;
  activationDate: Date;
  accountDetails: {
    username: string;
    password: string;
    mfa: string
  };
  payoutAmount: number,
  network: string;
  address: string
};

class AccountsPage extends React.Component<AccountsPageProps, AccountsPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: AccountsPageState = {
    isLoading: true,
    list: [],
    filter: { page: 1, pageSize: 128, total: 0 },
    isActivationModalOpen: false,
    isCancelModalOpen: false,
    isCredentialsModalOpen: false,
    isClaimModalOpen: false,
    subscriptionToActivate: null,
    subscriptionToCancel: null,
    subscriptionToPayout: null,
    activationDate: null,
    accountDetails: { username: null, password: null, mfa: null },
    payoutAmount: 0,
    network: "ERC20",
    address: '',
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection
    const queryParams = new URLSearchParams(this.props.location.search);
    console.log("accounts query params", queryParams)

    const filter: Filter = {
      fundTradingProgramId: queryParams.get("program") || "",
      status: (queryParams.get("status") as SubscriptionStatus) || null,

      page: parseInt(queryParams.get("page") || "1", 10),
      pageSize: parseInt(queryParams.get("pageSize") || "128", 10),
      total: 0
    };

    const offset = (filter.page - 1) * filter.pageSize;

    const response = await PortCentralServer.Repo.client.filterUserSubscriptions({
      fundTradingProgramId: filter.fundTradingProgramId,
      status: filter.status,
      limit: filter.pageSize,
      offset: offset
    });

    console.log("response here", response);

    if (response) {
      filter.total = response.total;

      const statusPriority: { [key in SubscriptionStatus]: number } = {
        [SubscriptionStatus.ACTIVE]: 1,
        [SubscriptionStatus.READY]: 2,
        [SubscriptionStatus.DRAFT]: 3,
        [SubscriptionStatus.FINISHED]: 4,
        [SubscriptionStatus.FAILED]: 5,
        [SubscriptionStatus.NOT_SET]: 6,
        [SubscriptionStatus.PAID]: 7
      };

      response.data.map(subscription => subscription.fundTradingProgramStageSequence++)

      this.setState(() => {
        return {
          filter: filter,
          list: response.data
            .filter(userSubscription => !userSubscription.userSubscriptionDeletedYn)
            .sort((a, b) => {
              // Compare based on status priority
              const statusOrderA = statusPriority[a.userSubscriptionStatus];
              const statusOrderB = statusPriority[b.userSubscriptionStatus];

              if (statusOrderA !== statusOrderB) {
                return statusOrderA - statusOrderB;
              }

              // If statuses are the same, compare by startDate in descending order
              const startDateA = new Date(a.userSubscriptionDateStart).getTime();
              const startDateB = new Date(b.userSubscriptionDateStart).getTime();

              return startDateB - startDateA; // Descending order
            }),
          isLoading: false
        };
      });
    } else {
      await this.setState((prevState) => {
        return {
          isLoading: false
        };
      });
    }
  };

  componentDidUpdate = async (prevProps: AccountsPageProps, prevState: AccountsPageState) => {

  };

  componentWillUnmount = async () => {
    // destroy connection
  };

  calculateProgress = (realizedPnl: number, profitTargetPercantage: number, accountSize: number) => {
    const profitTargetAmount = (profitTargetPercantage / 100) * accountSize;
    const progress = (realizedPnl / profitTargetAmount) * 100;

    if (progress > 100) {
      return 100;
    } else if (progress < 0 || !progress) {
      return 0;
    }

    return Math.round(progress);
  };

  submitPaymentForm(vendor: string, amount: number, symbol: string, inv: string, email: string): void {
    const form = this.createPaymentForm(vendor, amount, symbol, inv, email);
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  }

  createPaymentForm(vendor: string, amount: number, symbol: string, inv: string, email: string): HTMLFormElement {
    const form = document.createElement('form') as HTMLFormElement;
    form.method = 'POST';
    form.action = 'https\://bxcheckout.com/order_popup';

    form.appendChild(this.createHiddenInput('vendor', vendor));
    form.appendChild(this.createHiddenInput('amount', amount.toString())); // Convert number to string
    form.appendChild(this.createHiddenInput('symbol', symbol));
    form.appendChild(this.createHiddenInput('inv', inv));
    form.appendChild(this.createHiddenInput('email', email));

    return form;
  }

  createHiddenInput(name: string, value: string): HTMLInputElement {
    const input = document.createElement('input') as HTMLInputElement;
    input.type = 'hidden';
    input.name = name;
    input.value = value;
    return input;
  }

  payUserSubscription = (userSubsription: ViewUserSubscriptionProgressInfoDto) => {
    if (userSubsription.fundTradingProgramPrice && userSubsription.userSubscriptionPurchaseId && userSubsription.userEmail) {
      this.submitPaymentForm('123456789abcdef', userSubsription.fundTradingProgramPrice, 'USDT', userSubsription.userSubscriptionPurchaseId, userSubsription.userEmail);
    }
  }

  handleDateSubmit = async () => {
    const { activationDate, subscriptionToActivate } = this.state;

    if (activationDate && subscriptionToActivate) {
      await PortCentralServer.Repo.client.activateSubscription({
        userSubscriptionId: subscriptionToActivate,
        dateStart: activationDate
      });

      window.location.reload();
    }

    this.setState({ isActivationModalOpen: false, subscriptionToActivate: null });
  };

  handleActivationModalClose = () => {
    this.setState({ isActivationModalOpen: false, subscriptionToActivate: null });
  };

  handleClaimModalClose = () => {
    this.setState({ isClaimModalOpen: false });
  };

  handleClaimSubmit = async () => {
    const { subscriptionToPayout, network, address } = this.state;

    if (network && address) {
      await PortCentralServer.Repo.client.payoutSubscription({
        userSubscriptionId: subscriptionToPayout,
        network,
        address,
      });

      this.setState({
        isClaimModalOpen: false,
        network: "ERC20",
        address: null,
      });
    }
  };

  activateUserSubscription = async (userSubscriptionId: string) => {
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1);

    this.setState({
      isActivationModalOpen: true,
      subscriptionToActivate: userSubscriptionId,
      activationDate: tomorrow
    });
  }

  fetchAccountDetails = async (userSubscriptionId: string) => {
    const credentials = await PortCentralServer.Repo.client.getCredentials(userSubscriptionId);

    if (credentials) {
      this.setState({
        accountDetails: {
          username: credentials.username,
          password: credentials.password,
          mfa: credentials.mfa,
        },
        isCredentialsModalOpen: true,
      });
    }
  };

  displayActionButtons = (userSubsription: ViewUserSubscriptionProgressInfoDto) => {
    const { userSubscriptionStatus, currentStageIndexAllowProfitClaimYn } = userSubsription;

    if (userSubscriptionStatus === SubscriptionStatus.DRAFT) {
      return <>
        <button onClick={() => this.payUserSubscription(userSubsription)} className="btn-rounded btn btn-primary me-2">Pay</button>
        <button
          onClick={() => this.setState({ isCancelModalOpen: true, subscriptionToCancel: userSubsription.userSubscriptionId })}
          className="btn-rounded btn btn-warning"
        >
          Cancel
        </button>
      </>
    } else if (userSubscriptionStatus === SubscriptionStatus.READY) {
      return <button onClick={() => this.activateUserSubscription(userSubsription.userSubscriptionId)} className="btn-rounded btn btn-primary me-2">Activate</button>
    } else if (userSubscriptionStatus === SubscriptionStatus.FINISHED) {
      return <>
        {
          currentStageIndexAllowProfitClaimYn ?
            <button onClick={() => { this.setState({ isClaimModalOpen: true, payoutAmount: userSubsription.currentStageIndexCurrentProfitPercentage || 0, subscriptionToPayout: userSubsription.userSubscriptionId }) }} className="btn-rounded btn btn-primary me-2">Payout</button >
            : null
        }
        <button onClick={() => { window.location.replace('/dashboard') }} className="btn-rounded btn btn-secondary me-2">Progress</button>
      </>
    } else if (userSubscriptionStatus === SubscriptionStatus.ACTIVE || userSubscriptionStatus === SubscriptionStatus.FAILED) {
      return <button
        onClick={() => window.location.replace(`account-progress/${userSubsription.userSubscriptionId}/${userSubsription.currentStageIndexId}`)} // Redirect with state
        className="btn-rounded btn btn-primary me-2"
      >Progress</button>
    }
  }

  confirmCancel = async () => {
    if (this.state.subscriptionToCancel) {
      const userDeleted = await PortCentralServer.Repo.client.cancelUserSubscriptions(this.state.subscriptionToCancel);

      this.setState({
        isCancelModalOpen: false,
        subscriptionToCancel: null
      });

      if (userDeleted) {
        window.location.reload();
      }
    }
  };

  cancelModalClose = () => {
    this.setState({
      isCancelModalOpen: false,
      subscriptionToCancel: null
    });
  };

  handleCredentialsModalClose = () => {
    this.setState({
      isCredentialsModalOpen: false,
      accountDetails: { username: null, password: null, mfa: null }
    });
  };

  DateTimePickerContent = () => {
    return (
      <div className="align-items-center text-center">
        <h3>Select Subscription Activation Date</h3>
        <Flatpickr
          className="form-control d-block align-items-center text-center"
          placeholder="dd M,yyyy"
          options={{
            inline: true,
            altInput: true,
            altFormat: "F j, Y",
            dateFormat: "Y-m-d H:i",
            minDate: this.state.activationDate,
            onChange: (date) => { this.setState({ activationDate: date[0] }) }
          }}
        />
      </div>
    );
  };

  ClaimContent = () => {
    return (
      <div className="align-items-center text-center">
        <h3>Claim Payout</h3>
        <div className="form-group mt-3">
          <label>Payout Amount</label>
          <input
            disabled={true}
            type="text"
            className="form-control"
            value={`${this.state.payoutAmount} USDT`}
            placeholder="Enter payout amount"
          />
        </div>

        <div className="form-group mt-3">
          <label>Network</label>
          <select
            className="form-control"
            value={this.state.network}
            onChange={(e) => this.setState({ network: e.target.value })}
          >
            <option value="ERC20">ERC20</option>
            <option value="TRC20">TRC20</option>
          </select>
        </div>

        <div className="form-group mt-3">
          <label>Address</label>
          <input
            type="text"
            className="form-control"
            value={this.state.address}
            onChange={(e) => this.setState({ address: e.target.value })}
            placeholder="Enter wallet address"
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Layout>
          <div className="page-content">
            <BaseModal
              isOpen={this.state.isActivationModalOpen}
              content={<this.DateTimePickerContent />}
              title={"Choose subscription activation date"}
              onSubmitClick={() => this.handleDateSubmit()}
              onCloseClick={() => this.handleActivationModalClose()}
            />
            <BaseModal
              isOpen={this.state.isCancelModalOpen}
              content={<h6>Are you sure you want to cancel this subscription?</h6>}
              title="Confirm Cancellation"
              onSubmitClick={this.confirmCancel}
              onCloseClick={this.cancelModalClose}
              isWarning={true}
            />
            <BaseModal
              isOpen={this.state.isCredentialsModalOpen}
              content={
                <div>
                  <p><strong>Username:</strong> {this.state.accountDetails.username}</p>
                  <p><strong>Password:</strong> {this.state.accountDetails.password}</p>
                  <p><strong>2FA:</strong> {this.state.accountDetails.mfa}</p>
                </div>
              }
              title="Account Details"
              onSubmitClick={this.handleCredentialsModalClose}
              onCloseClick={this.handleCredentialsModalClose}
            />
            <BaseModal
              isOpen={this.state.isClaimModalOpen}
              content={<this.ClaimContent />}
              title={"Payout"}
              onSubmitClick={() => this.handleClaimSubmit()}
              onCloseClick={() => this.handleClaimModalClose()}
            />

            <div className="container-fluid">
              <Breadcrumbs title="Account" breadcrumbItem="Subscriptions" />
              <Row>
                <Col xl={12}>
                  {this.state.isLoading ? (
                    <LoaderComponent />
                  ) : (
                    <>
                      <div>
                        <CardBody>
                          {/* <Form method="get">
                            <Row className="mb-4">
                              <Col sm={6} className="col-xl">
                                <FormGroup className="mb-3">
                                  <Label className="form-label">Level</Label>
                                  <EnumSelectControl
                                    enumData={SubscriptionStatus}
                                    name="type"
                                    selectedValue={this.state.filter.status}
                                    className="form-control select2-search-disable"
                                  />
                                </FormGroup>
                              </Col>
                              <div className="col-xl col-sm-6 align-self-end">
                                <div className="mb-3">
                                  <input type="hidden" name="page" value={this.state.filter.page} />
                                  <input type="hidden" name="pageSize" value={this.state.filter.pageSize} />
                                  <Button type="submit" color="primary" className="w-md">
                                    Filter
                                  </Button>
                                </div>
                              </div>
                            </Row>
                          </Form> */}

                          <Row>
                            <Col xl={12} className="">
                              <div className="table-responsive scroll-horizontal-hidden">
                                <Row>
                                  {(this.state.list || []).map((item: ViewUserSubscriptionProgressInfoDto, key: number) => (
                                    <Col xl={3} key={key}>
                                      <div className="card">
                                        <div className="card-body text-center">
                                          <div className="d-flex flex-column align-items-center">
                                            <h5 className="text-truncate font-size-15 mb-1">
                                              <a className="text-dark" href="/projects-overview/9">
                                                {item.fundTradingProgramTitle}: ${Number(item.fundTradingProgramAccountSize).toLocaleString()}
                                              </a>
                                            </h5>
                                            <div className="d-flex flex-column align-items-center mt-2">
                                              <span className={`badge rounded-pill font-size-12 ${item.userSubscriptionStatus === SubscriptionStatus.ACTIVE || item.userSubscriptionStatus === SubscriptionStatus.PAID
                                                ? "badge-soft-success"
                                                : item.userSubscriptionStatus === SubscriptionStatus.DRAFT
                                                  ? "badge-soft-warning"
                                                  : item.userSubscriptionStatus === SubscriptionStatus.READY
                                                    ? "badge-soft-primary"
                                                    : item.userSubscriptionStatus === SubscriptionStatus.FINISHED
                                                      ? "badge-soft-info"
                                                      : "badge-soft-danger"
                                                }`} id="task-status">
                                                {item.userSubscriptionStatus}
                                              </span>
                                              {
                                                item.userSubscriptionStatus === SubscriptionStatus.ACTIVE ? <span
                                                  onClick={() => this.fetchAccountDetails(item.currentStageIndexUserSubscriptionCredentialId)}
                                                >
                                                  View Account Details
                                                </span> : <span className="visibility-hidden">View Account Details
                                                </span>
                                              }
                                              <ApexRadial
                                                dataColors='["--bs-primary"]'
                                                progress={this.calculateProgress(
                                                  item.currentStageIndexTotalRealizedPnl,
                                                  item.fundTradingProgramStageProfitTarget,
                                                  item.fundTradingProgramAccountSize
                                                )}
                                              />
                                            </div>
                                          </div>

                                          <Row className="justify-content-center mt-3">
                                            <Col md={12}>
                                              {
                                                item.userSubscriptionStatus === SubscriptionStatus.DRAFT || item.userSubscriptionStatus === SubscriptionStatus.READY || item.userSubscriptionStatus === SubscriptionStatus.PAID ?
                                                  <>
                                                    <p className="mb-1 visibility-hidden">
                                                      Activate to show statistics
                                                    </p>
                                                    <p className="mb-1">
                                                      <i className="bx bxs-info-circle"></i>
                                                      Activate to show statistics
                                                    </p>
                                                    <p className="mb-1 visibility-hidden">
                                                      Activate to show statistics
                                                    </p>
                                                  </> : <>
                                                    <p className="mb-1">
                                                      <i className="bx bxs-calendar"></i> Start Date: {moment(item.userSubscriptionDateStart).format("YYYY-MM-DD")}
                                                    </p>
                                                    <p className="mb-1">
                                                      <i className="bx bx-loader-circle"></i> Stage: {item.fundTradingProgramStageType === "PHASE"
                                                        ? item.fundTradingProgramStageSequence
                                                        : "LIVE"}
                                                    </p>
                                                    <p className="mb-1">
                                                      <i className="bx bx-money"></i> PNL: ${Number(item.currentStageIndexTotalRealizedPnl).toFixed(2)} USDT
                                                    </p>
                                                  </>
                                              }
                                            </Col>
                                          </Row>
                                        </div>
                                        <div className="px-4 py-3 border-top text-center">
                                          {this.displayActionButtons(item)}
                                        </div>
                                      </div>
                                    </Col>
                                  ))}
                                </Row>

                                <PagerComponent currentPage={this.state.filter.page} pageSize={this.state.filter.pageSize} totalItems={this.state.filter.total} />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Layout>
      </React.Fragment >
    );
  }
}

export default withRouterHook(AccountsPage);
