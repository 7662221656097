import React from "react";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import withRouterHook from "../../withRouterHook";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { FundTradingProgramDto } from "../../lib/drivers/dto/programs/FundTradingProgramDto";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
// import { AppContext } from "../context/AppProvider";
// import { Layout } from "../layout";
// import withRouterHook from "../withRouterHook";

const SUBTITLES = [
  { accountSize: "5000", text: "Kickstarter Tier", secondText: "Kickstarter Tier" },
  { accountSize: "10000", text: "Starter Plus Tier", secondText: "Kickstarter Tier" },
  { accountSize: "25000", text: "Accelerator Tier", secondText: "Kickstarter Tier" },
  { accountSize: "50000", text: "Advanced Tier", secondText: "Kickstarter Tier" },
  { accountSize: "100000", text: "Pro Tier", secondText: "Kickstarter Tier" },
  { accountSize: "200000", text: "Elite Tier", secondText: "Kickstarter Tier" },
  { accountSize: "500000", text: "Master Elite Tier", secondText: "Kickstarter Tier" },
  { accountSize: "1000000", text: "Grand Elite Tier", secondText: "Kickstarter Tier" },
];

export type PrisingPageProps = {};

type PrisingPageState = {
  here: boolean;
  programs: Array<any>;
  phases: number;
};

class PrisingPage extends React.Component<PrisingPageProps, PrisingPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: PrisingPageState = {
    here: true,
    programs: [],
    phases: 1
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection
    const programsList = await PortCentralServer.Repo.client.getFundTradingProgramList();

    // TODO: error handle on undefined?
    if (programsList) {
      this.setState({
        ...this.state,
        programs: programsList
      });
    }

    // getFundTradingProgramList
    // await this.setState((prevState) => {
    //   return { here: true };
    // });
  };

  componentDidUpdate = async (prevProps: PrisingPageProps, prevState: PrisingPageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  handleButtonPhaseClick = async (e: React.FormEvent<HTMLButtonElement>, phases: number) => {
    e.preventDefault();
    this.setState({ phases });
  };
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  findSubtitle = (programPrice: string) => {
    let subtitle = ""
    const currentProgramObjectPair = SUBTITLES.filter((element) => element.accountSize === programPrice)
    return subtitle = currentProgramObjectPair[0].text
  }
  // #endregion

  render() {
    console.log("programs", this.state.programs)
    const with_1_phase: FundTradingProgramDto[] = this.state.programs.filter((ele) => ele.numberStages == 1).sort((a, b) => a.accountSize - b.accountSize);
    const with_2_phases: FundTradingProgramDto[] = this.state.programs.filter((ele) => ele.numberStages == 2).sort((a, b) => a.accountSize - b.accountSize);
    const list: FundTradingProgramDto[] = this.state.phases == 1 ? with_1_phase : with_2_phases;

    return (
      <React.Fragment>
        <Layout>
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="Account" breadcrumbItem="Subscriptions" />
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="text-center mb-5">
                    <h4>Choose Your Funded Trading Program</h4>
                    <p className="text-center">
                      The key difference between 1-phase and 2-phase fund trading programs lies in the evaluation process. A 1-phase program gets you funded faster with just one assessment, while a 2-phase program offers a more detailed evaluation across two stages—but here's the kicker: Phase 2 is cheaper!
                    </p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-6 col-lg-4 text-center">
                  <div className="btn-group btn-group-example mb-3" role="group">
                    <button type="button" className={`w-xs btn btn-primary ${this.state.phases === 1 ? "active" : null}`} onClick={(e) => this.handleButtonPhaseClick(e, 1)}>
                      {/* <i className="mdi mdi-thumb-up" /> */}
                      Phase 1
                    </button>
                    <button type="button" className={`w-xs btn btn-primary ${this.state.phases === 2 ? "active" : null}`} onClick={(e) => this.handleButtonPhaseClick(e, 2)}>
                      {/* <i className="mdi mdi-thumb-down" /> */}
                      Phase 2
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                {list.map((program) => (
                  <div className="col-md-6 col-xl-3">
                    <div className="plan-box card">
                      <div className="p-4 card-body">
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <h5>
                              {program.title}: {Number(program.accountSize).toLocaleString()} USDT
                            </h5>
                            <p className="">{this.findSubtitle(program.accountSize.toString())}</p>
                          </div>
                          <div className="flex-shrink-0 ms-3">
                            {
                              program.discountPercentage ?
                                <span style={{ color: "gold" }}>{program.discountPercentage} % Discount</span> :
                                null
                            }
                          </div>
                        </div>
                        <div className="py-4">
                          <h2>
                            <sup>
                              <small>$</small>
                            </sup>{" "}
                            {
                              program.discountPercentage && program.discountPrice ?
                                <del>{Number(program.price).toLocaleString()} One time</del> :
                                <span>{Number(program.price).toLocaleString()} <span className="font-size-13">One time</span> </span>
                            }
                            {program.discountPercentage && program.discountPrice ?
                              <>
                                <br />
                                <sup>
                                  <small>$</small>
                                </sup>{" "}
                                <span style={{ color: "gold" }}>{Number(program.discountPrice).toLocaleString()} <span className="font-size-13">One time</span></span>
                              </> : null
                            }
                          </h2>
                        </div>
                        <div className="text-center plan-btn">
                          <a className="btn btn-primary btn-sm waves-effect waves-light " href={`/pricing-details/${program.fundTradingProgramId}`}>
                            Get Funded
                          </a>
                        </div>
                        <div className="plan-features mt-5">
                          <p>
                            <i className="bx bx-checkbox-square text-primary me-2" />
                            <span className="token-stage-bonus">{program.fundTradingProgramStages[0].maxDailyLoss}%</span> <span className="token-stage-cap">Max daily loss</span>
                          </p>
                          {program.fundTradingProgramStages[0].maxTotalLoss && (
                            <p>
                              <i className="bx bx-checkbox-square text-primary me-2" />
                              <span className="token-stage-bonus">{program.fundTradingProgramStages[0].maxTotalLoss}%</span> <span className="token-stage-cap">Max loss</span>
                            </p>
                          )}
                          {program.fundTradingProgramStages[0].maxTrailingLoss && (
                            <p>
                              <i className="bx bx-checkbox-square text-primary me-2" />
                              <span className="token-stage-bonus">{program.fundTradingProgramStages[0].maxTrailingLoss}%</span>{" "}
                              <span className="token-stage-cap">Max Trailing loss</span>
                            </p>
                          )}
                          <p>
                            <i className="bx bx-checkbox-square text-primary me-2" />
                            <span className="token-stage-bonus">{program.fundTradingProgramStages[0].profitTarget}%</span> <span className="token-stage-cap">Phase 1 target</span>
                          </p>
                          {program.fundTradingProgramStages.length > 2 && (
                            <p>
                              <i className="bx bx-checkbox-square text-primary me-2" />
                              <span className="token-stage-bonus">{program.fundTradingProgramStages[1].profitTarget}%</span> <span className="token-stage-cap">Phase 2 target</span>
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Layout>
      </React.Fragment >
    );
  }
}

export default withRouterHook(PrisingPage);
