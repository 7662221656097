import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const UserBadge = ({ userBadge }) => {
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-secondary align-items-center text-center">
          <Row>
            <Col xs="12">
              <div className="text-white p-3">
                <h5 className="text-white">Welcome Back, {userBadge?.firstName}!</h5>
                <p>BoneX Fund Trader</p>
              </div>
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0 align-items-center text-center">
          <Row>
            <Col sm="12">
              <div className="avatar-md profile-user-wid mb-4">
                <img
                  src={userBadge?.avatarUrl || "/images/default-user-image.png"}
                  alt={userBadge?.username}
                  className="img-thumbnail rounded-circle"
                />
              </div>
            </Col>

            <Col sm="12">
            <Row>
            <Col sm="4">
            <h5 className="font-size-15 text-truncate">
                {userBadge?.firstName} {userBadge?.lastName}
              </h5>
              <p className="mb-0 text-truncate">Rank: {userBadge?.rank}</p>
            </Col>
            <Col sm="4">
            <h5 className="font-size-15">{userBadge?.totalFinishedSubscriptions}</h5>
            <p className="mb-0">Subscriptions Completed</p>
            </Col>
            <Col sm="4">
            <Link to={`/profile`} className="btn btn-primary btn-sm">
                    Edit Profile <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
            </Col>
            </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default UserBadge;
