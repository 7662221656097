import React from "react";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import withRouterHook from "../../withRouterHook";
import { Button, Card, CardBody, CardTitle, Row } from "reactstrap";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";

export type AffiliateApplyProps = {};

type AffiliateApplyState = {
    names: string;
    email: string;
    promotionStrategy: string;
    expectedReach: string;
    placeOfResidence: string;
    selectedPlatforms: string[];
    isSubmitting: boolean;
    error: string | null;
    successMessage: string | null;
};

class AffiliateApply extends React.Component<AffiliateApplyProps, AffiliateApplyState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    state: AffiliateApplyState = {
        names: "",
        email: "",
        promotionStrategy: "",
        expectedReach: "",
        placeOfResidence: "",
        selectedPlatforms: [],
        isSubmitting: false,
        error: null,
        successMessage: null,
    };

    // #region Handlers
    handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        e.preventDefault();

        const { name, value } = e.target;
        this.setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = e.target;
        this.setState((prevState) => {
            const { selectedPlatforms } = prevState;
            if (checked) {
                return { selectedPlatforms: [...selectedPlatforms, value] };
            } else {
                return { selectedPlatforms: selectedPlatforms.filter((platform) => platform !== value) };
            }
        });
    };

    handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        this.setState({ isSubmitting: true, error: null, successMessage: null });

        const { names, email, promotionStrategy, expectedReach, placeOfResidence, selectedPlatforms } = this.state;

        try {
            const response = await PortCentralServer.Repo.client.userAffiliateApply({
                items: [
                    { question: "First Name / Last Name", answers: [names] },
                    { question: "Contact Email", answers: [email] },
                    { question: "How do you plan to promote our services?", answers: [promotionStrategy] },
                    { question: "Expected Reach", answers: [expectedReach] },
                    { question: "Place of Residence", answers: [placeOfResidence] },
                    { question: "Social Media Platforms", answers: selectedPlatforms },
                ]
            });

            if (response) {
                this.setState({
                    successMessage: "Your application has been submitted successfully!",
                    names: "",
                    email: "",
                    promotionStrategy: "",
                    expectedReach: "",
                    placeOfResidence: "",
                    selectedPlatforms: [],
                });
            } else {
                this.setState({ error: "There was an error submitting your application. Please try again." });
            }
        } catch (error) {
            this.setState({ error: "There was an error submitting your application. Please try again." });
        } finally {
            this.setState({ isSubmitting: false });
        }
    };
    // #endregion

    render() {
        const { names, email, promotionStrategy, expectedReach, placeOfResidence, selectedPlatforms, isSubmitting, error, successMessage } = this.state;

        return (
            <React.Fragment>
                <Layout>
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col">
                                    <Card>
                                        <CardBody>
                                            <CardTitle className="h4">Affiliate Program Application</CardTitle>
                                            <p className="card-title-desc">
                                                Please fill out the following form to apply for our affiliate program.
                                            </p>

                                            <form onSubmit={this.handleSubmit}>
                                                {/* Full Name */}
                                                <Row className="mb-3">
                                                    <label htmlFor="names" className="col-md-2 col-form-label">
                                                        Names
                                                    </label>
                                                    <div className="col-md-5">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="names"
                                                            id="names"
                                                            value={names}
                                                            onChange={this.handleInputChange}
                                                            required
                                                            placeholder="Enter your first and last name"
                                                        />
                                                    </div>
                                                    <label htmlFor="email" className="col-md-2 col-form-label">
                                                        Email
                                                    </label>
                                                    <div className="col-md-3">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="email"
                                                            id="email"
                                                            value={email}
                                                            onChange={this.handleInputChange}
                                                            required
                                                            placeholder="Enter your email for contact"
                                                        />
                                                    </div>
                                                </Row>

                                                {/* Promotion Strategy */}
                                                <Row className="mb-3">
                                                    <label htmlFor="promotionStrategy" className="col-md-2 col-form-label">
                                                        How do you plan to promote our services?
                                                    </label>
                                                    <div className="col-md-10">
                                                        <textarea
                                                            className="form-control"
                                                            name="promotionStrategy"
                                                            id="promotionStrategy"
                                                            value={promotionStrategy}
                                                            onChange={this.handleInputChange}
                                                            required
                                                            placeholder="Describe your strategy"
                                                        />
                                                    </div>
                                                </Row>

                                                {/* Social Media Platforms */}
                                                <Row className="mb-3">
                                                    <label className="col-md-2 col-form-label">
                                                        Social Media Platforms
                                                    </label>
                                                    <div className="col-md-10">
                                                        <div>
                                                            <input
                                                                type="checkbox"
                                                                value="Telegram"
                                                                onChange={this.handleCheckboxChange}
                                                                checked={selectedPlatforms.includes("Telegram")}
                                                            /> Telegram
                                                        </div>
                                                        <div>
                                                            <input
                                                                type="checkbox"
                                                                value="Discord"
                                                                onChange={this.handleCheckboxChange}
                                                                checked={selectedPlatforms.includes("Discord")}
                                                            /> Discord
                                                        </div>
                                                        <div>
                                                            <input
                                                                type="checkbox"
                                                                value="Facebook"
                                                                onChange={this.handleCheckboxChange}
                                                                checked={selectedPlatforms.includes("Facebook")}
                                                            /> Facebook
                                                        </div>
                                                        <div>
                                                            <input
                                                                type="checkbox"
                                                                value="Twitter"
                                                                onChange={this.handleCheckboxChange}
                                                                checked={selectedPlatforms.includes("Twitter")}
                                                            /> Twitter
                                                        </div>
                                                        <div>
                                                            <input
                                                                type="checkbox"
                                                                value="Instagram"
                                                                onChange={this.handleCheckboxChange}
                                                                checked={selectedPlatforms.includes("Instagram")}
                                                            /> Instagram
                                                        </div>
                                                        <div>
                                                            <input
                                                                type="checkbox"
                                                                value="Other"
                                                                onChange={this.handleCheckboxChange}
                                                                checked={selectedPlatforms.includes("Other")}
                                                            /> Other (please specify)
                                                            {selectedPlatforms.includes("Other") && (
                                                                <input
                                                                    type="text"
                                                                    className="form-control mt-2"
                                                                    placeholder="Specify other platforms"
                                                                    onChange={this.handleInputChange}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </Row>

                                                {/* Expected Reach */}
                                                <Row className="mb-3">
                                                    <label htmlFor="expectedReach" className="col-md-2 col-form-label">
                                                        Expected Reach
                                                    </label>
                                                    <div className="col-md-10">
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            name="expectedReach"
                                                            id="expectedReach"
                                                            value={expectedReach}
                                                            onChange={this.handleInputChange}
                                                            required
                                                            placeholder="Enter expected number of referrals"
                                                        />
                                                    </div>
                                                </Row>

                                                {/* Place of Residence */}
                                                <Row className="mb-3">
                                                    <label htmlFor="placeOfResidence" className="col-md-2 col-form-label">
                                                        Place of Residence
                                                    </label>
                                                    <div className="col-md-10">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="placeOfResidence"
                                                            id="placeOfResidence"
                                                            value={placeOfResidence}
                                                            onChange={this.handleInputChange}
                                                            required
                                                            placeholder="Enter your city and country"
                                                        />
                                                    </div>
                                                </Row>

                                                {error && (
                                                    <Row className="mb-3">
                                                        <div className="col-md-12 text-danger">{error}</div>
                                                    </Row>
                                                )}
                                                {successMessage && (
                                                    <Row className="mb-3">
                                                        <div className="col-md-12 text-success">{successMessage}</div>
                                                    </Row>
                                                )}

                                                <Row>
                                                    <div className="col-md-12">
                                                        <Button type="submit" color="primary" disabled={isSubmitting}>
                                                            {isSubmitting ? "Submitting..." : "Submit Application"}
                                                        </Button>
                                                    </div>
                                                </Row>
                                            </form>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout>
            </React.Fragment>
        );
    }
}

export default withRouterHook(AffiliateApply);
