import React from "react";
import { Spinner } from "reactstrap";
import { AppContext } from "../context/AppProvider";

export type LoaderComponentProps = {};

type LoaderComponentState = {};

export class LoaderComponent extends React.Component<LoaderComponentProps, LoaderComponentState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: LoaderComponentState = {};

  // #region React Events
  componentDidMount = async () => {
    // setup connection
  };

  componentDidUpdate = async (prevProps: LoaderComponentProps, prevState: LoaderComponentState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Spinner color="primary" className="position-absolute top-50 start-50" />
      </React.Fragment>
    );
  }
}
