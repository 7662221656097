import React from "react";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import withRouterHook from "../../withRouterHook";
import { Button, Card, CardBody, CardTitle, Row } from "reactstrap";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";

export type ContactPageProps = {};

type ContactPageState = {
  subject: string;
  text: string;
  isSubmitting: boolean;
  error: string | null;
  successMessage: string | null;
};

class ContactPage extends React.Component<ContactPageProps, ContactPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: ContactPageState = {
    subject: "",
    text: "",
    isSubmitting: false,
    error: null,
    successMessage: null,
  };

  // #region Handlers
  handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.preventDefault();

    const { name, value } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.setState({ isSubmitting: true, error: null, successMessage: null });

    const { subject, text } = this.state;

    try {
      const response = await PortCentralServer.Repo.client.sendContactForm({ userEmail: '', subject, text });

      if (response) {
        this.setState({
          successMessage: "Your message has been sent successfully!",
          subject: "",
          text: "",
        });
      } else {
        this.setState({ error: "There was an error sending your message. Please try again." });

      }
    } catch (error) {
      this.setState({ error: "There was an error sending your message. Please try again." });
    } finally {
      this.setState({ isSubmitting: false });
    }
  };
  // #endregion

  render() {
    const { subject, text, isSubmitting, error, successMessage } = this.state;

    return (
      <React.Fragment>
        <Layout>
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <Card>
                    <CardBody>
                      <CardTitle className="h4">Contact form</CardTitle>
                      <p className="card-title-desc">
                        Here you can submit any questions you have for our team. We will respond ASAP. Thank you!
                      </p>

                      <form onSubmit={this.handleSubmit}>
                        <Row className="mb-3">
                          <label
                            htmlFor="subject-input"
                            className="col-md-2 col-form-label"
                          >
                            Subject
                          </label>
                          <div className="col-md-10">
                            <input
                              className="form-control"
                              type="text"
                              name="subject"
                              id="subject-input"
                              value={subject}
                              onChange={this.handleInputChange}
                              required
                              placeholder="Enter subject"
                            />
                          </div>
                        </Row>

                        <Row className="mb-3">
                          <label
                            htmlFor="text-input"
                            className="col-md-2 col-form-label"
                          >
                            Text
                          </label>
                          <div className="col-md-10">
                            <textarea
                              className="form-control"
                              name="text"
                              id="text-input"
                              value={text}
                              onChange={this.handleInputChange}
                              required
                              placeholder="Enter your message"
                            />
                          </div>
                        </Row>

                        {error && (
                          <Row className="mb-3">
                            <div className="col-md-10 offset-md-2">
                              <p className="text-danger">{error}</p>
                            </div>
                          </Row>
                        )}

                        {successMessage && (
                          <Row className="mb-3">
                            <div className="col-md-10 offset-md-2">
                              <p className="text-success">{successMessage}</p>
                            </div>
                          </Row>
                        )}

                        <Row className="mb-3 justify-content-center">
                          <div className="col-md-1 justify-content-center">
                            <Button
                              type="submit"
                              color="primary"
                              className="w-md"
                              disabled={isSubmitting}
                            >
                              {isSubmitting ? "Sending..." : "Send"}
                            </Button>
                          </div>
                        </Row>
                      </form>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouterHook(ContactPage);
