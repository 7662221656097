import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useLocation } from "react-router-dom";
import { Alert, Card, CardBody, Col, Container, Form, Input, Label, Row } from "reactstrap";
import { AppContext } from "../../context/AppProvider";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import withRouterHook from "../../withRouterHook";

// import images
import logo from "../../assets/images/logo-light.svg";
import profile from "../../assets/images/profile-img.png";


export type AcceptInvitationPageProps = {
  location?: ReturnType<typeof useLocation>;
};

type AcceptInvitationPageState = {
  here: boolean;
  valid: boolean;
  successMessage: string;
  errorMessage: string;
};

class AcceptInvitationPage extends React.Component<AcceptInvitationPageProps, AcceptInvitationPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: AcceptInvitationPageState = {
    here: true,
    valid: false,
    successMessage: null,
    errorMessage: null
  };

  refEmail: React.RefObject<HTMLInputElement>;
  refNewPassword: React.RefObject<HTMLInputElement>;
  refRepeatNewPassword: React.RefObject<HTMLInputElement>;
  refCaptcha: React.RefObject<ReCAPTCHA>;

  constructor(props: AcceptInvitationPageProps) {
    super(props);

    this.refEmail = React.createRef();
    this.refNewPassword = React.createRef();
    this.refRepeatNewPassword = React.createRef();
    this.refCaptcha = React.createRef();
  }

  // #region React Events
  componentDidMount = async () => {
    // setup connection

    await this.setState((prevState) => {
      return { here: true };
    });
  };

  componentDidUpdate = async (prevProps: AcceptInvitationPageProps, prevState: AcceptInvitationPageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  async handleSubmitSignIn(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const recaptchaToken = this.refCaptcha.current.getValue();
    const widgetId = this.refCaptcha.current.getWidgetId();

    if (!recaptchaToken) {
      this.context.notifyError("CAPTCHA not verified");
      return;
    }

    const queryParams = new URLSearchParams(this.props.location.search);

    const token = queryParams.get("token");
    const email = this.refEmail.current.value;
    const newPassword = this.refNewPassword.current.value;
    const repeatNewPassword = this.refRepeatNewPassword.current.value;

    let errorMessage: string = "";

    if (newPassword.length == 0) {
      errorMessage = "New password not provided";
    } else if (repeatNewPassword.length == 0) {
      errorMessage = "Repeat new password password not provided";
    } else if (newPassword != repeatNewPassword) {
      errorMessage = "Passwords do not match";
    }

    if (errorMessage) {
      await this.setState((prevState) => {
        return { errorMessage: errorMessage };
      });
    } else {
      try {
        const result = await PortCentralServer.Repo.client.authAcceptInvitation({
          token: token,
          email: email,
          newPassword: newPassword
        });

        if (result) {
          await this.setState((prevState) => {
            return { successMessage: result.message };
          });
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  }

  handleReCaptchaChange = async (token: string) => {
    await this.setState((prevState) => {
      return { valid: true };
    });
  };
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary-subtle">
                    <Row>
                      <Col xs={7}>
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p>Sign in to continue to BoneX Fund Trader.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profile} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img src={logo} alt="" height="34" />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      {this.state.errorMessage ? (
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                          {this.state.errorMessage}
                        </Alert>
                      ) : null}

                      {this.state.successMessage ? (
                        <Alert color="success" style={{ marginTop: "13px" }}>
                          {this.state.successMessage}
                        </Alert>
                      ) : null}

                      <Form className="form-horizontal" onSubmit={(e) => this.handleSubmitSignIn(e)}>
                        <div className="form-group mb-3">
                          <Label className="form-label">Email</Label>
                          <Input className="form-control" type="text" innerRef={this.refEmail} />
                          {/* {validation.touched.username && validation.errors.username ? <FormFeedback type="invalid">{validation.errors.username}</FormFeedback> : null} */}
                        </div>
                        <div className="form-group mb-3">
                          <Label className="form-label">New Password</Label>
                          <Input className="form-control" type="password" innerRef={this.refNewPassword} minLength={5} />
                          {/* {validation.touched.username && validation.errors.username ? <FormFeedback type="invalid">{validation.errors.username}</FormFeedback> : null} */}
                        </div>
                        <div className="form-group mb-3">
                          <Label className="form-label">Repeat New Password</Label>
                          <Input className="form-control" type="password" innerRef={this.refRepeatNewPassword} minLength={5} />
                          {/* {validation.touched.username && validation.errors.username ? <FormFeedback type="invalid">{validation.errors.username}</FormFeedback> : null} */}
                        </div>

                        <div className="mt-3 d-grid">
                          <ReCAPTCHA ref={this.refCaptcha} sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={this.handleReCaptchaChange} />
                        </div>

                        <div className="mt-3 d-grid">
                          <button className="btn btn-primary w-md " type="submit" disabled={!this.state.valid}>
                            Create User
                          </button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Go back to{" "}
                    <Link to="/sign-in" className="font-weight-medium text-primary">
                      Login
                    </Link>{" "}
                  </p>
                  <p>© {new Date().getFullYear()} BoneX Fund Trader</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouterHook(AcceptInvitationPage);
