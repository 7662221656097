
export enum CentralServerPathParamKind {
    uid = "{uid}",
    tournamentId = "{tournamentId}",
    exchangePartnerId = "{exchangePartnerId}",
    year = "{year}",
    month = "{month}",
    fundTradingProgramId = "{fundTradingProgramId}",
    userSubscriptionId= "{userSubscriptionId}",
    userSubscriptionStageIndexId= "{userSubscriptionStageIndexId}"
}

export enum CentralServerQueryParamKind {
    // Unknown = "unknown",
}

export enum CentralServerEndpointKind {
    // auth
    auth_sign_in = "auth/sign-in",
    auth_sign_up = "auth/sign-up",
    auth_request_password_reset = "auth/request-password-reset",
    auth_reset_password = "auth/reset-password",
    auth_accept_invitation = "auth/accept-invitation",

    // communication
    send_contact_form= "communication/send-contact-form",

    // profile
    profile_change_password = "profile/change-password",
    user_badge = "profile/badge",

    // fund-trading-program
    fund_trading_programs = "fund-trading/programs",
    fund_trading_programs_id = "fund-trading/programs/{fundTradingProgramId}",

    // subscriptions
    user_subscriptions_filter = "user-subscriptions/filter",
    user_subscriptions_billings = "user-subscriptions/purchases/filter",
    user_subscriptions_payouts = "user-subscriptions/payouts/filter",
    user_subscriptions_buy = "user-subscriptions/purchases/buy",
    user_subscriptions_activate = "user-subscriptions/activate",
    user_subscriptions_payout = "user-subscriptions/payouts/request",
    user_subscriptions_infos_filter = "user-subscriptions/infos/filter",
    user_subscriptions_cancel = "user-subscriptions/cancel/{userSubscriptionId}",
    user_subscriptions_credentials = "user-subscriptions/credentials/{userSubscriptionId}",
    user_subscriptions_stages = "user-subscriptions/{userSubscriptionId}/stage-indexes",
    user_subscriptions_stage_index = "user-subscriptions/{userSubscriptionId}/stage-indexes/{userSubscriptionStageIndexId}",
    user_subscriptions_stage_index_days = "user-subscriptions/{userSubscriptionId}/stage-indexes/{userSubscriptionStageIndexId}/days",
    user_subscriptions_stage_index_day_orders = "user-subscriptions/{userSubscriptionId}/stage-indexes/{userSubscriptionStageIndexId}/orders/filter"
}
