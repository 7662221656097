import React, { ChangeEvent } from "react";
import { AppContext } from "../../context/AppProvider";
import { Layout } from "../../layout";
import withRouterHook from "../../withRouterHook";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { useLocation } from "react-router-dom";
import { FundTradingProgramDto } from "../../lib/drivers/dto/programs/FundTradingProgramDto";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import { MoonPayBuyWidget } from "@moonpay/moonpay-react";
import { PurcaseProviderType } from "../../lib/drivers/dto/enums/PurcaseProviderType";

const DETAILS: { [key: number]: { accountSizeNum: string; title: string; subtitle: string } } = {
  5000: { accountSizeNum: "5000", title: "Kickstarter Tier", subtitle: "Start Small, Dream Big: Begin Your Trading Journey with Essential Funding." },
  10000: { accountSizeNum: "10000", title: "Starter Plus Tier", subtitle: "Step Up Your Game: More Funding for Confident Beginnings." },
  25000: { accountSizeNum: "25000", title: "Accelerator Tier", subtitle: "Boost Your Potential: Enhanced Funding for Ambitious Traders." },
  50000: { accountSizeNum: "50000", title: "Advanced Tier", subtitle: "Take the Lead: Substantial Funding for Growing Traders." },
  100000: { accountSizeNum: "100000", title: "Pro Tier", subtitle: "Maximize Your Impact: Premium Funding for Serious Traders." },
  200000: { accountSizeNum: "200000", title: "Elite Tier", subtitle: "Unlock the Ultimate: Top-Tier Funding for Expert-Level Trading." }
};

export type PricingDetailsPageProps = {
  location?: ReturnType<typeof useLocation>;
};

type PaymentOptions = {
  usdt: boolean,
  card: boolean
}

type MoonpayData = {
  visible: boolean,
  baseCurrencyCode: string,
  baseCurrencyAmount: number,
  defaultCurrencyCode: string,
  walletAddress: string,
  showWalletAddressForm: boolean,
  lockAmount: boolean,
  email: string,
  externalTransactionId: string,
  externalCustomerId: string
}

type PricingDetailsPageState = {
  here: boolean;
  activeTab: number;
  program: FundTradingProgramDto;
  programId: string;
  readCheck: boolean;
  paymentOption: PaymentOptions;
  moonpayData: MoonpayData;
};

class PricingDetailsPage extends React.Component<PricingDetailsPageProps, PricingDetailsPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: PricingDetailsPageState = {
    here: true,
    activeTab: 1,
    program: null,
    programId: "",
    readCheck: false,
    paymentOption: {
      usdt: true,
      card: false
    },
    moonpayData: {
      visible: false,
      baseCurrencyCode: "usd",
      baseCurrencyAmount: 0,
      // TODO => Change currency and wallet
      defaultCurrencyCode: "eth",
      walletAddress: process.env.REACT_APP_MOONPAY_RECIEVE_WALLET,
      showWalletAddressForm: false,
      lockAmount: true,
      email: "",
      externalTransactionId: "",
      externalCustomerId: ""
    }
  };

  // #region React Events
  componentDidMount = async () => {
    const paths = this.props.location.pathname.split("/");
    const fundTradingProgramId = paths[paths.length - 1];
    // setup connection
    const currProgram = await PortCentralServer.Repo.client.getFundTradingProgram(fundTradingProgramId);

    const queryParam = new URLSearchParams(this.props.location.search);
    this.setState({
      ...this.state,
      program: currProgram,
      programId: fundTradingProgramId,
    });
  };

  componentDidUpdate = async (prevProps: PricingDetailsPageProps, prevState: PricingDetailsPageState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  toggleTab = (tab: number) => {
    if (this.state.activeTab !== tab) {
      // var modifiedSteps = [...passedSteps, tab]
      if (tab >= 1 && tab <= 2) {
        this.setState({
          ...this.state,
          activeTab: tab
        });
        // setactiveTab(tab)
        // setPassedSteps(modifiedSteps)
      }
    }
  };

  handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      readCheck: event.target.checked
    })
  };

  submitPaymentForm(vendor: string, amount: number, symbol: string, inv: string, email: string): void {
    if (this.state.readCheck) {
      const form = this.createPaymentForm(vendor, amount, symbol, inv, email);
      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    }
  }

  createPaymentForm(vendor: string, amount: number, symbol: string, inv: string, email: string): HTMLFormElement {
    const form = document.createElement('form') as HTMLFormElement;
    form.method = 'POST';
    form.action = 'https\://bxcheckout.com/order_popup';

    form.appendChild(this.createHiddenInput('vendor', vendor));
    form.appendChild(this.createHiddenInput('amount', amount.toString())); // Convert number to string
    form.appendChild(this.createHiddenInput('symbol', symbol));
    form.appendChild(this.createHiddenInput('inv', inv));
    form.appendChild(this.createHiddenInput('email', email));

    return form;
  }

  createHiddenInput(name: string, value: string): HTMLInputElement {
    const input = document.createElement('input') as HTMLInputElement;
    input.type = 'hidden';
    input.name = name;
    input.value = value;
    return input;
  }

  calculateTotalMoonpayPrice(amount: number) {
    const processingFeePercentage = 0.045;
    const minFee = 3.99;
    const networkFee = 2;

    // Calculate the fee (either 4.5% of the amount or $3.99, whichever is higher)
    const fee = Math.max(amount * processingFeePercentage, minFee);

    // Return the total cost (amount + fee)
    return amount + fee + networkFee;
  }

  processBuy = async () => {
    if (this.state.readCheck) {
      if (this.state.paymentOption.usdt) {
        const { userSubscriptionPurchaseId, userEmail } = await PortCentralServer.Repo.client.buySubscription({
          fundTradingProgramId: this.state.programId,
          provider: PurcaseProviderType.BXCHECKOUT
        });
        this.createInvoice(userSubscriptionPurchaseId, userEmail);
      } else if (this.state.paymentOption.card) {
        const { userSubscriptionPurchaseId, userEmail, userId } = await PortCentralServer.Repo.client.buySubscription({
          fundTradingProgramId: this.state.programId,
          provider: PurcaseProviderType.MOONPAY,
          network: "ethereum",
          symbol: this.state.moonpayData.defaultCurrencyCode.toUpperCase(),
          address: this.state.moonpayData.walletAddress,
        });

        this.setState({
          ...this.state,
          moonpayData: {
            ...this.state.moonpayData,
            visible: true,
            baseCurrencyAmount: this.calculateTotalMoonpayPrice(Number(this.state.program?.discountPrice || this.state.program.price)),
            email: userEmail,
            externalTransactionId: userSubscriptionPurchaseId,
            externalCustomerId: userId
          }
        })
      }
    }
  }

  createInvoice = async (userSubscriptionPurchaseId: string, userEmail: string) => {
    this.submitPaymentForm('123456789abcdef', this.state.program?.discountPrice || this.state.program.price, 'USDT', userSubscriptionPurchaseId, userEmail);
  }

  handleGetSignature = async (url: string): Promise<string> => {
    return await PortCentralServer.Repo.client.getSignature(url);
  }
  // #endregion

  // #region State Setters
  setActiveTab = (tab: number) => {
    this.setState({
      ...this.state,
      activeTab: tab
    });
  };
  // #endregion

  // #region Functions
  // #endregion

  render() {
    let currentTitle = { accountSizeNum: "", title: "", subtitle: "" };
    let programTitle = "";
    let programSubtitle = "";
    if (this.state.program !== null) {
      currentTitle = DETAILS[Number(this.state.program.accountSize)];
      programTitle = currentTitle.title;
      programSubtitle = currentTitle.subtitle;
    }
    const buttonText = this.state.activeTab === 2 ? "Pay now" : "Next";

    // const programTitle = currentTitle[0].title

    return (
      <React.Fragment>
        <Layout>
          {this.state.moonpayData.visible ?
            <MoonPayBuyWidget
              visible={this.state.moonpayData.visible}
              variant="overlay"
              baseCurrencyCode={this.state.moonpayData.baseCurrencyCode}
              baseCurrencyAmount={this.state.moonpayData.baseCurrencyAmount.toString()}
              defaultCurrencyCode={this.state.moonpayData.defaultCurrencyCode}
              walletAddress={this.state.moonpayData.walletAddress}
              showWalletAddressForm={this.state.moonpayData.showWalletAddressForm.toString()}
              lockAmount={this.state.moonpayData.lockAmount.toString()}
              email={this.state.moonpayData.email}
              externalTransactionId={this.state.moonpayData.externalTransactionId}
              externalCustomerId={this.state.moonpayData.externalCustomerId}
              onUrlSignatureRequested={this.handleGetSignature}
            /> : <></>
          }
          <div className="page-content">
            <div className="container-fluid">
              <Breadcrumbs title="Subscription" breadcrumbItem="Details" />
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="text-center mb-5">
                    <h4>
                      Select Your Funded Trading Program</h4>
                    <p className="text-center">
                      To do this, uniform guidelines and clear evaluation criteria are needed. Standardizing processes across programs can streamline the path for traders.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-4">Basic Wizard</h4>
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <li className={`nav-item ${this.state.activeTab === 1 ? "current" : ""}`}>
                          <a
                            className={`nav-link ${this.state.activeTab === 1 ? "current" : ""}`}
                            onClick={() => {
                              this.setActiveTab(1);
                            }}
                          // disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">1.</span> Program Details
                          </a>
                        </li>
                        <li className={`nav-item ${this.state.activeTab === 2 ? "current" : ""}`}>
                          <a
                            className={`nav-link ${this.state.activeTab === 2 ? "active" : ""}`}
                            onClick={() => {
                              this.setActiveTab(2);
                            }}
                          // disabled={!(passedSteps || []).includes(2)}
                          >
                            <span className="number">2.</span> Payment
                          </a>
                        </li>
                        {/* <li className={`nav-item ${this.state.activeTab === 3 ? "current" : ""}`}>
                          <a
                            className={`nav-link ${this.state.activeTab === 3 ? "active" : ""}`}
                            onClick={() => {
                              this.setActiveTab(3);
                            }}
                            // disabled={!(passedSteps || []).includes(3)}
                          >
                            <span className="number">3.</span> Bank Details
                          </a>
                        </li> */}
                        {/* <li className={`nav-item ${this.state.activeTab === 4 ? "current" : ""}`}>
                          <a
                            className={`nav-link ${this.state.activeTab === 4 ? "active" : ""}`}
                            onClick={() => {
                              this.setActiveTab(4);
                            }}
                            // disabled={!(passedSteps || []).includes(4)}
                          >
                            <span className="number">4.</span> Confirm Detail
                          </a>
                        </li> */}
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <div className={`tab-content body ${this.state.activeTab === 1 ? "active" : ""}`}>
                        <div className={`tab-pane ${this.state.activeTab === 1 ? "active" : ""}`}>
                          {/* <form>
                            <div className="row">
                              <div className="col lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-firstname-input1">First name</label>
                                  <input type="text" className="form-control" id="basicpill-firstname-input1" placeholder="Enter Your First Name" />
                                </div>
                              </div>
                              <div className="col lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-lastname-input2">Last name</label>
                                  <input type="text" className="form-control" id="basicpill-lastname-input2" placeholder="Enter Your Last Name" />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-phoneno-input3">Phone</label>
                                  <input type="text" className="form-control" id="basicpill-phoneno-input3" placeholder="Enter Your Phone No." />
                                </div>
                              </div>
                              <div className="col lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-email-input4">Email</label>
                                  <input type="email" className="form-control" id="basicpill-email-input4" placeholder="Enter Your Email ID" />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col lg-12">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-address-input1">Address</label>
                                  <textarea id="basicpill-address-input1" className="form-control" rows={2} placeholder="Enter Your Address" />
                                </div>
                              </div>
                            </div>
                          </form> */}
                          {
                            // currentTitle[0].
                            <div>
                              <h5>
                                {currentTitle && currentTitle.title} ${currentTitle && currentTitle.accountSizeNum}{" "}
                              </h5>
                              <h5 className="mb-4">
                                {/* {currentTitle && currentTitle[0].title} {currentTitle && currentTitle[0].accountSizeNum} {" "} */}
                                {
                                  this.state.program?.discountPrice ?
                                    <>
                                      <del>Price :{" "}
                                        <span className="me-2">
                                          <b>${this.state.program && this.state.program.price} USD</b>
                                        </span>{" "}
                                      </del>
                                      <br />
                                      <>
                                        Discount Price :{" "}
                                        <span className="me-2">
                                          <b style={{ color: "gold" }}>${this.state.program && this.state.program.discountPrice} USD</b>
                                        </span>{" "}
                                      </>
                                    </> :
                                    <></>
                                }
                              </h5>
                              <p className="mb-4">{currentTitle && currentTitle.subtitle}</p>{" "}
                            </div>
                          }
                          <div className="row">
                            {this.state.program !== null &&
                              this.state.program.fundTradingProgramStages.map((stage, index) => (
                                <div className="plan-features mt-5 col-md-4">
                                  <h3>
                                    {stage.type} {stage.type === "PHASE" ? stage.sequence + 1 : ""}
                                  </h3>
                                  <p>
                                    <i className="fa fa-caret-right font-size-16 align-middle text-primary me-2" />
                                    <span className="token-stage-bonus">{stage.maxDailyLoss}%</span> <span className="token-stage-cap">Max daily loss</span>
                                  </p>
                                  {stage.maxTotalLoss && (
                                    <p>
                                      <i className="fa fa-caret-right font-size-16 align-middle text-primary me-2" />
                                      <span className="token-stage-bonus">{stage.maxTotalLoss}%</span> <span className="token-stage-cap">Max loss</span>
                                    </p>
                                  )}
                                  {stage.maxTrailingLoss && (
                                    <p>
                                      <i className="fa fa-caret-right font-size-16 align-middle text-primary me-2" />
                                      <span className="token-stage-bonus">{stage.maxTrailingLoss}%</span> <span className="token-stage-cap">Max Trailing loss</span>
                                    </p>
                                  )}
                                  {stage.profitTarget !== null && (
                                    <p>
                                      <i className="fa fa-caret-right font-size-16 align-middle text-primary me-2" />
                                      <span className="token-stage-bonus">{stage.profitTarget} %</span> <span className="token-stage-cap">Phase {[index + 1]} target</span>
                                    </p>
                                  )}
                                  {/* {this.state.program !== null && this.state.program.fundTradingProgramStages.length > 2 && (
                                  <p>
                                    <i className="fa fa-caret-right font-size-16 align-middle text-primary me-2" />
                                    <span className="token-stage-bonus">
                                      {this.state.program !== null ? this.state.program.fundTradingProgramStages[1].profitTarget : ""}%
                                    </span>{" "}
                                    <span className="token-stage-cap">Phase 2 target</span>
                                  </p>
                                )} */}
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                      <div className={`tab-content body ${this.state.activeTab === 2 ? "active" : ""}`}>
                        <div className={`tab-pane ${this.state.activeTab === 2 ? "active" : ""}`}>
                          {/* <form>
                            <div className="row">
                              <div className="col lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-pancard-input5">PAN Card</label>
                                  <input type="text" className="form-control" id="basicpill-pancard-input5" placeholder="Enter Your PAN No." />
                                </div>
                              </div>

                              <div className="col lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-vatno-input6">VAT/TIN No.</label>
                                  <input type="text" className="form-control" id="basicpill-vatno-input6" placeholder="Enter Your VAT/TIN No." />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-cstno-input7">CST No.</label>
                                  <input type="text" className="form-control" id="basicpill-cstno-input7" placeholder="Enter Your CST No." />
                                </div>
                              </div>

                              <div className="col lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-servicetax-input8">Service Tax No.</label>
                                  <input type="text" className="form-control" id="basicpill-servicetax-input8" placeholder="Enter Your Service Tax No." />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-companyuin-input9">Company UIN</label>
                                  <input type="text" className="form-control" id="basicpill-companyuin-input9" placeholder="Enter Your Company UIN" />
                                </div>
                              </div>

                              <div className="col lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-declaration-input10">Declaration</label>
                                  <input type="text" className="form-control" id="basicpill-declaration-input10" placeholder="Declaration Details" />
                                </div>
                              </div>
                            </div>
                          </form> */}
                          <form>
                            <div className="row">
                              <div className="col lg-6">
                                <h3>Payment method</h3>
                                <div className="form-check mb-3">
                                  <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" onClick={() => this.setState({ ...this.state, paymentOption: { card: false, usdt: true } })} checked={this.state.paymentOption.usdt} />
                                  <label className="form-check-label" htmlFor="exampleRadios1">
                                    USDT
                                  </label>
                                  <br />
                                  {/* <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option1" onClick={() => this.setState({ ...this.state, paymentOption: { card: true, usdt: false } })} checked={this.state.paymentOption.card} />
                                  <label className="form-check-label" htmlFor="exampleRadios2"   >
                                    Credit Card
                                  </label> */}
                                </div>
                              </div>

                              <div className="col lg-6">
                                <div className="mb-3">
                                  <p>
                                    By depositing USDT or other supported cryptocurrencies through our platform, you agree to our terms and conditions. Deposits are final, non-refundable, and subject to blockchain network fees. Please ensure the accuracy of your deposit address, as we are not responsible for errors or losses.
                                  </p>
                                  <div className="form-check mb-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={this.state.readCheck}
                                      onChange={this.handleCheckboxChange}
                                    />
                                    <label
                                      className="form-check-label bold"
                                      htmlFor="check"
                                    >
                                      I have read and understood all the information about the Funded Trading Programs.
                                    </label>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </form>
                        </div>
                      </div>
                      <div className={`tab-content body ${this.state.activeTab === 3 ? "active" : ""}`}>
                        <div className={`tab-pane ${this.state.activeTab === 3 ? "active" : ""}`}>
                          <form>
                            <div className="row">
                              <div className="col lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-namecard-input11">Name on Card</label>
                                  <input type="text" className="form-control" id="basicpill-namecard-input11" placeholder="Enter Your Name on Card" />
                                </div>
                              </div>

                              <div className="col lg-6">
                                <div className="mb-3">
                                  <label>Credit Card Type</label>
                                  <select className="form-select">
                                    <option>Select Card Type</option>
                                    <option value="AE">American Express</option>
                                    <option value="VI">Visa</option>
                                    <option value="MC">MasterCard</option>
                                    <option value="DI">Discover</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-cardno-input12">Credit Card Number</label>
                                  <input type="text" className="form-control" id="basicpill-cardno-input12" placeholder="Credit Card Number" />
                                </div>
                              </div>

                              <div className="col lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-card-verification-input0">Card Verification Number</label>
                                  <input type="text" className="form-control" id="basicpill-card-verification-input0" placeholder="Credit Verification Number" />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-expiration-input13">Expiration Date</label>
                                  <input type="text" className="form-control" id="basicpill-expiration-input13" placeholder="Card Expiration Date" />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className={`tab-content body ${this.state.activeTab === 4 ? "active" : ""}`}>
                        <div className={`tab-pane ${this.state.activeTab === 4 ? "active" : ""}`}>
                          <div className="row justify-content-center">
                            <div className="col lg-6">
                              <div className="text-center">
                                <div className="mb-4">
                                  <i className="mdi mdi-check-circle-outline text-success display-4" />
                                </div>
                                <div>
                                  <h5>Confirm Detail</h5>
                                  <p className="">If several languages coalesce, the grammar of the resulting</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        <li className={this.state.activeTab === 1 ? "previous disabled" : "previous"}>
                          <a
                            href="#"
                            onClick={() => {
                              this.toggleTab(this.state.activeTab - 1);
                            }}
                          >
                            Previous
                          </a>
                        </li>
                        <li className={(!this.state.readCheck && this.state.activeTab === 2) ? "next disabled" : "next"}>
                          <a
                            href="#"
                            onClick={() => {
                              this.toggleTab(this.state.activeTab + 1);

                              if (this.state.activeTab === 2) {
                                this.processBuy();
                              }
                            }}
                          >
                            {buttonText}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title mb-4">Basic Wizard</h4>
                    <div className="wizard clearfix">
                      <div className="steps clearfix">
                        <ul>
                          <li className="current nav-item">
                            <a className="current nav-link">
                              <span className="number">1.</span> Seller Details
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link disabled">
                              <span className="number">2.</span> Company Document
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link disabled">
                              <span className="number">3.</span> Bank Details
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link disabled">
                              <span className="number">4.</span> Confirm Detail
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="content clearfix">
                        <div className="tab-content body">
                          <div className="tab-pane active">
                            <form className="">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label htmlFor="basicpill-firstname-input1" className="form-label">
                                      First name
                                    </label>
                                    <input id="basicpill-firstname-input1" placeholder="Enter Your First Name" type="text" className="form-control form-control" />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label htmlFor="basicpill-lastname-input2" className="form-label">
                                      Last name
                                    </label>
                                    <input id="basicpill-lastname-input2" placeholder="Enter Your Last Name" type="text" className="form-control form-control" />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label htmlFor="basicpill-phoneno-input3" className="form-label">
                                      Phone
                                    </label>
                                    <input id="basicpill-phoneno-input3" placeholder="Enter Your Phone No." type="text" className="form-control form-control" />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <label htmlFor="basicpill-email-input4" className="form-label">
                                      Email
                                    </label>
                                    <input id="basicpill-email-input4" placeholder="Enter Your Email ID" type="email" className="form-control form-control" />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="mb-3">
                                    <label htmlFor="basicpill-address-input1" className="form-label">
                                      Address
                                    </label>
                                    <textarea id="basicpill-address-input1" className="form-control" rows={2} placeholder="Enter Your Address" defaultValue={""} />
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                          <div className="tab-pane">
                            <div>
                              <form className="">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="mb-3">
                                      <label htmlFor="basicpill-pancard-input5" className="form-label">
                                        PAN Card
                                      </label>
                                      <input id="basicpill-pancard-input5" placeholder="Enter Your PAN No." type="text" className="form-control form-control" />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="mb-3">
                                      <label htmlFor="basicpill-vatno-input6" className="form-label">
                                        VAT/TIN No.
                                      </label>
                                      <input id="basicpill-vatno-input6" placeholder="Enter Your VAT/TIN No." type="text" className="form-control form-control" />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="mb-3">
                                      <label htmlFor="basicpill-cstno-input7" className="form-label">
                                        CST No.
                                      </label>
                                      <input id="basicpill-cstno-input7" placeholder="Enter Your CST No." type="text" className="form-control form-control" />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="mb-3">
                                      <label htmlFor="basicpill-servicetax-input8" className="form-label">
                                        Service Tax No.
                                      </label>
                                      <input id="basicpill-servicetax-input8" placeholder="Enter Your Service Tax No." type="text" className="form-control form-control" />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="mb-3">
                                      <label htmlFor="basicpill-companyuin-input9" className="form-label">
                                        Company UIN
                                      </label>
                                      <input id="basicpill-companyuin-input9" placeholder="Enter Your Company UIN" type="text" className="form-control form-control" />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="mb-3">
                                      <label htmlFor="basicpill-declaration-input10" className="form-label">
                                        Declaration
                                      </label>
                                      <input id="basicpill-Declaration-input10" placeholder="Declaration Details" type="text" className="form-control form-control" />
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                          <div className="tab-pane">
                            <div>
                              <form className="">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="mb-3">
                                      <label htmlFor="basicpill-namecard-input11" className="form-label">
                                        Name on Card
                                      </label>
                                      <input id="basicpill-namecard-input11" placeholder="Enter Your Name on Card" type="text" className="form-control form-control" />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="mb-3">
                                      <label className="form-label">Credit Card Type</label>
                                      <select className="form-select">
                                        <option>Select Card Type</option>
                                        <option value="AE">American Express</option>
                                        <option value="VI">Visa</option>
                                        <option value="MC">MasterCard</option>
                                        <option value="DI">Discover</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="mb-3">
                                      <label htmlFor="basicpill-cardno-input12" className="form-label">
                                        Credit Card Number
                                      </label>
                                      <input id="basicpill-cardno-input12" placeholder="Credit Card Number" type="text" className="form-control form-control" />
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="mb-3">
                                      <label htmlFor="basicpill-card-verification-input0" className="form-label">
                                        Card Verification Number
                                      </label>
                                      <input id="basicpill-card-verification-input0" placeholder="Credit Verification Number" type="text" className="form-control form-control" />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="mb-3">
                                      <label htmlFor="basicpill-expiration-input13" className="form-label">
                                        Expiration Date
                                      </label>
                                      <input id="basicpill-expiration-input13" placeholder="Card Expiration Date" type="text" className="form-control form-control" />
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                          <div className="tab-pane">
                            <div className="row justify-content-center">
                              <div className="col-lg-6">
                                <div className="text-center">
                                  <div className="mb-4">
                                    <i className="mdi mdi-check-circle-outline text-success display-4" />
                                  </div>
                                  <div>
                                    <h5>Confirm Detail</h5>
                                    <p className="text-muted">If several languages coalesce, the grammar of the resulting</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="actions clearfix">
                        <ul>
                          <li className="previous disabled">
                            <a href="/form-wizard">Previous</a>
                          </li>
                          <li className="next">
                            <a href="/form-wizard">Next</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {/* <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title mb-4">Basic Wizard</h4>
                <div className="wizard clearfix">
                  <div className="steps clearfix">
                    <ul>
                      <li className="current nav-item">
                        <a className="current nav-link">
                          <span className="number">1.</span> Seller Details
                        </a>
                      </li>
                      <li className="nav-item">
                        <a  className="nav-link disabled">
                          <span className="number">2.</span> Company Document
                        </a>
                      </li>
                      <li className="nav-item">
                        <a  className="nav-link disabled">
                          <span className="number">3.</span> Bank Details
                        </a>
                      </li>
                      <li className="nav-item">
                        <a  className="nav-link disabled">
                          <span className="number">4.</span> Confirm Detail
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="content clearfix">
                    <div className="tab-content body">
                      <div className="tab-pane active">
                        <form className="">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="basicpill-firstname-input1" className="form-label">
                                  First name
                                </label>
                                <input id="basicpill-firstname-input1" placeholder="Enter Your First Name" type="text" className="form-control form-control" />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="basicpill-lastname-input2" className="form-label">
                                  Last name
                                </label>
                                <input id="basicpill-lastname-input2" placeholder="Enter Your Last Name" type="text" className="form-control form-control" />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="basicpill-phoneno-input3" className="form-label">
                                  Phone
                                </label>
                                <input id="basicpill-phoneno-input3" placeholder="Enter Your Phone No." type="text" className="form-control form-control" />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="basicpill-email-input4" className="form-label">
                                  Email
                                </label>
                                <input id="basicpill-email-input4" placeholder="Enter Your Email ID" type="email" className="form-control form-control" />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="mb-3">
                                <label htmlFor="basicpill-address-input1" className="form-label">
                                  Address
                                </label>
                                <textarea id="basicpill-address-input1" className="form-control" rows={2} placeholder="Enter Your Address" defaultValue={""} />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="tab-pane">
                        <div>
                          <form className="">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-pancard-input5" className="form-label">
                                    PAN Card
                                  </label>
                                  <input id="basicpill-pancard-input5" placeholder="Enter Your PAN No." type="text" className="form-control form-control" />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-vatno-input6" className="form-label">
                                    VAT/TIN No.
                                  </label>
                                  <input id="basicpill-vatno-input6" placeholder="Enter Your VAT/TIN No." type="text" className="form-control form-control" />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-cstno-input7" className="form-label">
                                    CST No.
                                  </label>
                                  <input id="basicpill-cstno-input7" placeholder="Enter Your CST No." type="text" className="form-control form-control" />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-servicetax-input8" className="form-label">
                                    Service Tax No.
                                  </label>
                                  <input id="basicpill-servicetax-input8" placeholder="Enter Your Service Tax No." type="text" className="form-control form-control" />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-companyuin-input9" className="form-label">
                                    Company UIN
                                  </label>
                                  <input id="basicpill-companyuin-input9" placeholder="Enter Your Company UIN" type="text" className="form-control form-control" />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-declaration-input10" className="form-label">
                                    Declaration
                                  </label>
                                  <input id="basicpill-Declaration-input10" placeholder="Declaration Details" type="text" className="form-control form-control" />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="tab-pane">
                        <div>
                          <form className="">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-namecard-input11" className="form-label">
                                    Name on Card
                                  </label>
                                  <input id="basicpill-namecard-input11" placeholder="Enter Your Name on Card" type="text" className="form-control form-control" />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label className="form-label">Credit Card Type</label>
                                  <select className="form-select">
                                    <option>Select Card Type</option>
                                    <option value="AE">American Express</option>
                                    <option value="VI">Visa</option>
                                    <option value="MC">MasterCard</option>
                                    <option value="DI">Discover</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-cardno-input12" className="form-label">
                                    Credit Card Number
                                  </label>
                                  <input id="basicpill-cardno-input12" placeholder="Credit Card Number" type="text" className="form-control form-control" />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-card-verification-input0" className="form-label">
                                    Card Verification Number
                                  </label>
                                  <input id="basicpill-card-verification-input0" placeholder="Credit Verification Number" type="text" className="form-control form-control" />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-expiration-input13" className="form-label">
                                    Expiration Date
                                  </label>
                                  <input id="basicpill-expiration-input13" placeholder="Card Expiration Date" type="text" className="form-control form-control" />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="tab-pane">
                        <div className="row justify-content-center">
                          <div className="col-lg-6">
                            <div className="text-center">
                              <div className="mb-4">
                                <i className="mdi mdi-check-circle-outline text-success display-4" />
                              </div>
                              <div>
                                <h5>Confirm Detail</h5>
                                <p className="text-muted">If several languages coalesce, the grammar of the resulting</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="actions clearfix">
                    <ul>
                      <li className="previous disabled">
                        <a href="/form-wizard">Previous</a>
                      </li>
                      <li className="next">
                        <a href="/form-wizard">Next</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title mb-4">Basic Wizard</h4>
                <div className="wizard clearfix">
                  <div className="steps clearfix">
                    <ul>
                      <div
                        className="nav-item"
                        // className={classnames({ current: activeTab === 1 })}
                      >
                        <div
                          className="nav-a"
                          // className={classnames({ current: activeTab === 1 })}
                          // onClick={() => {
                          //   setactiveTab(1)
                          // }}
                          // disabled={!(passedSteps || []).includes(1)}
                        >
                          <span className="number">1.</span> Seller Details
                        </div>
                      </div>
                      <div
                        className="nav-item"
                        // className={classnames({ current: activeTab === 2 })}
                      >
                        <div
                          className="nav-a"
                          // className={classnames({ active: activeTab === 2 })}
                          // onClick={() => {
                          //   setactiveTab(2)
                          // }}
                          // disabled={!(passedSteps || []).includes(2)}
                        >
                          <span className="number">2.</span> Company Document
                        </div>
                      </div>
                      <div
                        className="nav-item"
                        // className={classnames({ current: activeTab === 3 })}
                      >
                        <div
                          className="nav-a"
                          // className={classnames({ active: activeTab === 3 })}
                          // onClick={() => {
                          //   setactiveTab(3)
                          // }}
                          // disabled={!(passedSteps || []).includes(3)}
                        >
                          <span className="number">3.</span> Bank Details
                        </div>
                      </div>
                      <div
                        className="nav-item"
                        // className={classnames({ current: activeTab === 4 })}
                      >
                        <div
                          className="nav-a"
                          // className={classnames({ active: activeTab === 4 })}
                          onClick={() => {
                            // setactiveTab(4)
                          }}
                          // disabled={!(passedSteps || []).includes(4)}
                        >
                          <span className="number">4.</span> Confirm Detail
                        </div>
                      </div>
                    </ul>
                  </div>
                  <div className="content clearfix">
                    <div
                      className="tab-content body"
                      // activeTab={this.state.activeTab}
                    >
                      <div className="tab-pane">
                        <form>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="basicpill-firstname-input1">First name</label>
                                <input type="text" className="form-control" id="basicpill-firstname-input1" placeholder="Enter Your First Name" />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="basicpill-lastname-input2">Last name</label>
                                <input type="text" className="form-control" id="basicpill-lastname-input2" placeholder="Enter Your Last Name" />
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="basicpill-phoneno-input3">Phone</label>
                                <input type="text" className="form-control" id="basicpill-phoneno-input3" placeholder="Enter Your Phone No." />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label htmlFor="basicpill-email-input4">Email</label>
                                <input type="email" className="form-control" id="basicpill-email-input4" placeholder="Enter Your Email ID" />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="mb-3">
                                <label htmlFor="basicpill-address-input1">Address</label>
                                <textarea
                                  id="basicpill-address-input1"
                                  className="form-control"
                                  // rows="2"
                                  placeholder="Enter Your Address"
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="tab-pane">
                        <div>
                          <form>
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-pancard-input5">PAN Card</label>
                                  <input type="text" className="form-control" id="basicpill-pancard-input5" placeholder="Enter Your PAN No." />
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-vatno-input6">VAT/TIN No.</label>
                                  <input type="text" className="form-control" id="basicpill-vatno-input6" placeholder="Enter Your VAT/TIN No." />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-cstno-input7">CST No.</label>
                                  <input type="text" className="form-control" id="basicpill-cstno-input7" placeholder="Enter Your CST No." />
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-servicetax-input8">Service Tax No.</label>
                                  <input type="text" className="form-control" id="basicpill-servicetax-input8" placeholder="Enter Your Service Tax No." />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-companyuin-input9">Company UIN</label>
                                  <input type="text" className="form-control" id="basicpill-companyuin-input9" placeholder="Enter Your Company UIN" />
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-declaration-input10">Declaration</label>
                                  <input type="text" className="form-control" id="basicpill-Declaration-input10" placeholder="Declaration Details" />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="tab-pane">
                        <div>
                          <form>
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-namecard-input11">Name on Card</label>
                                  <input type="text" className="form-control" id="basicpill-namecard-input11" placeholder="Enter Your Name on Card" />
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label>Credit Card Type</label>
                                  <select className="form-select">
                                    <option
                                    // defaultValue
                                    >
                                      Select Card Type
                                    </option>
                                    <option value="AE">American Express</option>
                                    <option value="VI">Visa</option>
                                    <option value="MC">MasterCard</option>
                                    <option value="DI">Discover</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-cardno-input12">Credit Card Number</label>
                                  <input type="text" className="form-control" id="basicpill-cardno-input12" placeholder="Credit Card Number" />
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-card-verification-input0">Card Verification Number</label>
                                  <input type="text" className="form-control" id="basicpill-card-verification-input0" placeholder="Credit Verification Number" />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <label htmlFor="basicpill-expiration-input13">Expiration Date</label>
                                  <input type="text" className="form-control" id="basicpill-expiration-input13" placeholder="Card Expiration Date" />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="tab-pane">
                        <div className="row justify-content-center">
                          <div className="col-lg-6">
                            <div className="text-center">
                              <div className="mb-4">
                                <i className="mdi mdi-check-circle-outline text-success display-4" />
                              </div>
                              <div>
                                <h5>Confirm Detail</h5>
                                <p className="text-muted">If several languages coalesce, the grammar of the resulting</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="actions clearfix">
                    <ul>
                      <li className={this.state.activeTab === 1 ? "previous disabled" : "previous"}>
                        <a
                          href="#"
                          onClick={() => {
                            this.toggleTab(this.state.activeTab - 1);
                          }}
                        >
                          Previous
                        </a>
                      </li>
                      <li className={this.state.activeTab === 4 ? "next disabled" : "next"}>
                        <a
                          href="#"
                          onClick={() => {
                            this.toggleTab(this.state.activeTab + 1);
                          }}
                        >
                          Next
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </Layout >
      </React.Fragment >
    );
  }
}

export default withRouterHook(PricingDetailsPage);
