import React from "react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";

export type SidebarContentProps = {};

type SidebarContentState = {};

export class SidebarContent extends React.Component<SidebarContentProps, SidebarContentState> {
  ref: React.RefObject<SimpleBar>;

  constructor(props: SidebarContentProps) {
    super(props);

    this.ref = React.createRef();
  }

  render() {
    return (
      <React.Fragment>
        <SimpleBar className="h-100" ref={this.ref}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li><Link to="/dashboard"><i className="bx bxs-dashboard"></i><span>Dashboard</span></Link></li>
              <li><Link to="/profile"><i className="bx bx-user"></i><span>Profile</span></Link></li>
              <li><Link to="/earns"><i className="bx bxs-data"></i><span>Earns</span></Link></li>
              <li><Link to="/accounts"><i className="bx bxs-user-detail"></i><span>Accounts</span></Link></li>
              {/* <li><Link to="/rank"><i className="bx bx-list-ol"></i><span>Rank</span></Link></li> */}
              {/* <li><Link to="/notifications"><i className="bx bx-notification"></i><span>Notifications</span></Link></li> */}
              <li><Link to="/billing"><i className="bx bx-receipt"></i><span>Billing</span></Link></li>
              <li><Link to="/contact"><i className="bx bx-message-rounded-dots"></i><span>Contact</span></Link></li>
              <li><Link to="/pricing"><i className="bx bx-dollar"></i><span>Pricing</span></Link></li>
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    );
  }
}
