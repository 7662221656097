import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export type BaseUniModalProps = {
  size?: string; // sm, lg, xl
  onSubmitClick(): void;
  onCloseClick(): void;
  isWarning?: boolean;
};

export type BaseModalProps = BaseUniModalProps & {
  title: string;
  content: React.ReactNode;
  isOpen: boolean;
};

type BaseModalState = {
  here: boolean;
};

export class BaseModal extends React.Component<BaseModalProps, BaseModalState> {
  state: BaseModalState = {
    here: true
  };

  // #region React Events
  componentDidMount = async () => {
    // setup connection

    this.setState((prevState) => {
      return { here: true };
    });
  };

  componentDidUpdate = async (prevProps: BaseModalProps, prevState: BaseModalState) => {
    // execute
  };

  componentWillUnmount = async () => {
    // destroy connection
  };
  // #endregion

  // #region Handlers
  onCloseClick = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (this.props.onCloseClick) {
      this.props.onCloseClick();
    }
  };

  onSubmitClick = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (this.props.onSubmitClick) {
      this.props.onSubmitClick();
    }
  };
  // #endregion

  // #region State Setters
  // #endregion

  // #region Functions
  // #endregion

  render() {
    return (
      <React.Fragment>
        <Modal isOpen={this.props.isOpen} toggle={this.onCloseClick} size={this.props.size} centered={true} unmountOnClose={true}>
          <ModalHeader toggle={this.onCloseClick}>{this.props.title}</ModalHeader>
          <ModalBody className="py-3 px-5">{this.props.content}</ModalBody>
          <ModalFooter>
            <button type="button" onClick={this.onCloseClick} className="btn btn-secondary" data-dismiss="modal">
              Close
            </button>
            <button type="button" className={`btn btn-${this.props.isWarning ? 'warning' : 'secondary'}`} onClick={this.onSubmitClick}>
              {this.props.isWarning ? "Cancel" : "Okay"}
            </button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}
