export enum SubscriptionPurchaseStatus {
    NOT_SET = "NOT_SET",
    PENDING = "PENDING", // The payment has been initiated but not yet completed. This could be because the user has not yet provided payment, or the payment is still processing.
    COMPLETED = "COMPLETED", // The payment has been successfully processed, and the funds have been received.
    FAILED = "FAILED", // The payment was attempted, but it failed due to some issue (e.g., insufficient funds, network error, incorrect details).
    CANCELLED = "CANCELLED", // The payment was initiated but was cancelled by the user before it could be completed.
    REFUNDED = "REFUNDED", // The payment was completed, but the funds were later returned to the user, typically because of a refund request.
    EXPIRED = "EXPIRED", // The payment was initiated, but the time limit for completing the payment passed without the user finalizing the transaction.
    PROCESSING = "PROCESSING", // The payment is currently being processed, and confirmation is pending. This status is often used for transactions involving multiple steps or third-party services that may take some time.
    UNDER_REVIEW = "UNDER_REVIEW", // The payment is flagged for manual review due to potential issues like fraud detection or unusual activity. It will be completed or rejected after review.
    PARTIALLY_COMPLETED = "PARTIALLY_COMPLETED", // Some payments, particularly in crypto or multi-part transactions, may be partially completed if only part of the required amount has been received.
  }