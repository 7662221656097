import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import TableContainer from "../../components/Common/TableContainer";
import { AppContext } from "../../context/AppProvider";
import withRouterHook from "../../withRouterHook";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { LoaderComponent } from "../../components/LoaderComponent";
import { Layout } from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs";
import { SubscriptionPurchaseStatus } from "../../lib/drivers/dto/enums/SubscriptionPurchaseStatus";

export type BillingPageProps = {};

type BillingPageState = {
  isLoading: boolean;
  billingData: {
    status: string;
    symbol: string;
    network: string;
    address: string;
    transactionHash: string;
    dateCreated: string;
  }[];
};

class BillingPage extends React.Component<BillingPageProps, BillingPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  state: BillingPageState = {
    isLoading: true,
    billingData: [],
  };

  async componentDidMount() {
    try {
      const response = await PortCentralServer.Repo.client.getUserBillings();

      const billingData = response.data.map((billing: any) => ({
        status: billing.status,
        symbol: billing.symbol,
        network: billing.network,
        address: billing.address,
        transactionHash: billing.transactionHash,
        dateCreated: new Date(billing.dateCreated).toLocaleString(),
      }));

      this.setState({ billingData });
    } catch (error) {
      console.error("Error fetching billing data:", error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  // Function to display relevant text for status
  getStatusText = (status: SubscriptionPurchaseStatus) => {
    switch (status) {
      case SubscriptionPurchaseStatus.PENDING:
        return "Pending";
      case SubscriptionPurchaseStatus.COMPLETED:
        return "Completed";
      case SubscriptionPurchaseStatus.FAILED:
        return "Failed";
      case SubscriptionPurchaseStatus.CANCELLED:
        return "Cancelled";
      case SubscriptionPurchaseStatus.REFUNDED:
        return "Refunded";
      case SubscriptionPurchaseStatus.EXPIRED:
        return "Expired";
      case SubscriptionPurchaseStatus.PROCESSING:
        return "Processing";
      case SubscriptionPurchaseStatus.UNDER_REVIEW:
        return "Under Review";
      case SubscriptionPurchaseStatus.PARTIALLY_COMPLETED:
        return "Partially Completed";
      default:
        return "Unknown Status";
    }
  };

  render() {
    const statusColorMap: { [key in SubscriptionPurchaseStatus]: string } = {
      [SubscriptionPurchaseStatus.PENDING]: "orange",
      [SubscriptionPurchaseStatus.COMPLETED]: "#66FF00",
      [SubscriptionPurchaseStatus.FAILED]: "red",
      [SubscriptionPurchaseStatus.CANCELLED]: "gray",
      [SubscriptionPurchaseStatus.REFUNDED]: "blue",
      [SubscriptionPurchaseStatus.EXPIRED]: "purple",
      [SubscriptionPurchaseStatus.PROCESSING]: "yellow",
      [SubscriptionPurchaseStatus.UNDER_REVIEW]: "darkorange",
      [SubscriptionPurchaseStatus.PARTIALLY_COMPLETED]: "lightblue",
      [SubscriptionPurchaseStatus.NOT_SET]: "white",
    };

    const getStatusText = (status: SubscriptionPurchaseStatus) => {
      if (status in SubscriptionPurchaseStatus) {
        return status;
      } else {
        console.warn("Unknown status:", status); // Log unknown statuses
        return "Unknown Status";
      }
    };

    const columns = [
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }: { value: SubscriptionPurchaseStatus }) => (
          <span style={{ color: statusColorMap[value] || "black" }}>
            {getStatusText(value)}
          </span>
        ),
      },
      { Header: "Symbol", accessor: "symbol" },
      { Header: "Network", accessor: "network" },
      { Header: "Address", accessor: "address" },
      { Header: "Transaction Hash", accessor: "transactionHash" },
      { Header: "Date Created", accessor: "dateCreated" },
    ];

    return (
      <Layout>
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Billing" breadcrumbItem="History" />
            <Row>
              <Col xl="12">
                {this.state.isLoading ? (
                  <LoaderComponent />
                ) : (
                  <Card>
                    <CardBody>
                      <div className="mb-4 h4 card-title">Billing History</div>
                      <TableContainer
                        columns={columns}
                        data={this.state.billingData}
                        isGlobalFilter={false}
                        isAddOptions={false}
                        customPageSize={10}
                      />
                    </CardBody>
                  </Card>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withRouterHook(BillingPage);
