import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import TableContainer from "../../components/Common/TableContainer";
import { AppContext } from "../../context/AppProvider";
import withRouterHook from "../../withRouterHook";
import { PortCentralServer } from "../../lib/domain/Ports/PortCentralServer";
import { LoaderComponent } from "../../components/LoaderComponent";
import { Layout } from "../../layout";
import { Breadcrumbs } from "../../layout/Breadcrumbs";

export type AffiliateProps = {};

type AffiliateState = {
    isLoading: boolean;
    invitationUserData: {
        username: string;
        totalPurchases: number;
        totalPurchasesAmount: number;
    }[];
    userDetails: {
        title: string;
        description: string;
    }[];
    invitationCode: string;
};

class Affiliate extends React.Component<AffiliateProps, AffiliateState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    state: AffiliateState = {
        isLoading: true,
        invitationUserData: [],
        invitationCode: `${window.location.origin}/sign-up`,
        userDetails: []
    };

    async componentDidMount() {
        try {
            const invitationCode = await PortCentralServer.Repo.client.getInvitationCode();
            const invitedUsers = await PortCentralServer.Repo.client.getInvitedUsers();

            const invitationUserData = [];
            let totalPurchaseShareAmount = 0;


            for (const user of invitedUsers.data) {
                invitationUserData.push({
                    username: user.invitedUsername,
                    totalPurchases: user.totalPurchases,
                    totalPurchasesAmount: user.totalPurchaseShareAmount
                });

                totalPurchaseShareAmount += Number(user.totalPurchaseShareAmount);
            }

            this.setState({
                invitationUserData: invitationUserData,
                invitationCode: `${window.location.origin}/sign-up?invitation=${invitationCode}`,
                userDetails: [
                    {
                        title: "Total Invited Users",
                        description: invitedUsers.total.toLocaleString()
                    },
                    {
                        title: "Total Share Amount",
                        description: `${totalPurchaseShareAmount.toLocaleString()} USD`
                    }
                ]
            })
        } catch (error) {
            console.error("Error fetching payout data:", error);
        } finally {
            this.setState({ isLoading: false });
        }
    }

    render() {
        const columns = [
            { Header: "Username", accessor: "username" },
            { Header: "Total Purchases", accessor: "totalPurchases" },
            { Header: "Total Purchases Split Amount (USD)", accessor: "totalPurchasesAmount" },
        ];

        return (
            <Layout>
                <div className="page-content">
                    <div className="container-fluid">
                        <Breadcrumbs title="Affiliate" breadcrumbItem="Users" />
                        <Row>
                            <Col xl="12">
                                {this.state.isLoading ? (
                                    <LoaderComponent />
                                ) : (
                                    <>
                                        <Row className="justify-content-center">
                                            <Col xl="6" className="text-center">
                                                <h5>Your Affiliate Code</h5>
                                                <a
                                                    href={`${this.state.invitationCode}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="form-control text-center"
                                                    style={{ color: 'gold', textDecoration: 'underline' }}
                                                >
                                                    {`${this.state.invitationCode}`}
                                                </a>
                                                <br />
                                                <div className="">
                                                    <button
                                                        className="btn btn-outline-primary"
                                                        type="button"
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(`${this.state.invitationCode}`);
                                                        }}
                                                    >
                                                        <i className="bi bi-clipboard">copy</i>
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                        <br />
                                        <br />

                                        <Row className="justify-content-center">
                                            {this.state.userDetails.map((detail, key) => (
                                                <Col md="3" key={key}>
                                                    <Card className="mini-stats-wid">
                                                        <CardBody>
                                                            <div className="d-flex">
                                                                <div className="flex-grow-1">
                                                                    <p className="fw-medium">{detail.title}</p>
                                                                    <h4 className="mb-0">{detail.description}</h4>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                        <Card>
                                            <CardBody>
                                                <div className="mb-4 h4 card-title">Affiliate Users</div>

                                                <Row>
                                                    <TableContainer
                                                        columns={columns}
                                                        data={this.state.invitationUserData}
                                                        isGlobalFilter={false}
                                                        isAddOptions={false}
                                                        customPageSize={10}
                                                    />
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default withRouterHook(Affiliate);
