import axios, { AxiosRequestConfig } from 'axios';
import { AuthCookieManager } from '../AuthCookieManager';
import { ServerEndpointBuilder } from "../core/ServerDriver/ServerEndpointBuilder";
import { CentralServerEndpointKind, CentralServerPathParamKind } from "./CentralServerClientEnums";
import { AcceptInvitationRequestDto } from './dto/auth/AcceptInvitationRequestDto';
import { AcceptInvitationResponseDto } from './dto/auth/AcceptInvitationResponseDto';
import { ChangePasswordRequestDto } from './dto/auth/ChangePasswordRequestDto';
import { ChangePasswordResponseDto } from './dto/auth/ChangePasswordResponseDto';
import { PasswordResetRequestDto } from "./dto/auth/PasswordResetRequestDto";
import { PasswordResetResponseDto } from './dto/auth/PasswordResetResponseDto';
import { SignInRequestDto } from './dto/auth/SignInRequestDto';
import { SignInResponseDto } from './dto/auth/SignInResponseDto';
import { SignUpRequestDto } from './dto/auth/SignUpRequestDto';
import { SignUpResponseDto } from './dto/auth/SignUpResponseDto';
import { CreatePasswordResetDto } from "./dto/profile/CreatePasswordResetDto";
import { CreatePasswordResetResponseDto } from './dto/profile/CreatePasswordResetResponseDto';
import { ActivateUserSubscriptionDto } from './dto/subscriptions/ActivateUserSubscriptionDto';
import { CreateUserSubscriptionDto } from './dto/subscriptions/CreateUserSubscriptionDto';
import { FilterUserSubscriptionRequestDto } from './dto/subscriptions/FilterUserSubscriptionRequestDto';
import { UserSubscriptionDto } from './dto/subscriptions/UserSubscriptionDto';
import { FundTradingProgramDto } from './dto/programs/FundTradingProgramDto';
import { FilterViewUserSubscriptionProgressInfoResponseDto } from './dto/subscriptions/FilterViewUserSubscriptionProgressInfoResponseDto';
import { UrlParamPair } from '../core/ServerDriver/UrlParamPair';
import { UserSubscriptionPurchaseDto } from './dto/subscriptions/UserSubscriptionPurchaseDto';
import { CredentialsDto } from './dto/subscriptions/CredentialsDto';
import { UserSubscriptionStageIndexDto } from './dto/subscriptions/UserSubscriptionStageIndexDto';
import { UserSubscriptionStageIndexDayDto } from './dto/subscriptions/UserSubscriptionStageIndexDayDto';
import { UserBadgeDto } from './dto/user/UserBadgeDto';
import { FilterUserSubscriptionStageIndexDayOrderDto } from "./dto/subscriptions/FilterUserSubscriptionStageIndexDayOrderDto";
import { PayoutSubscriptionDto } from './dto/subscriptions/PayoutSubscriptionDto';
import { ContactFormDto } from './dto/profile/ContactFormDto';
import { FilterPayoutsDto } from "./dto/subscriptions/FilterPayoutsDto.1";
import { FilterBillingsDto } from "./dto/subscriptions/FilterBillingsDto.1";
import { FilterViewUserSubscriptionProgressInfoRequestDto } from './dto/subscriptions/FilterViewUserSubscriptionProgressInfoRequestDto';
import { FilterViewUserAffiliatePurchaseShareResponseDto } from './dto/userAffiliates/FilterViewUserAffiliatePurchaseShareResponseDto';
import { UserAffiliatePayoutDataDto } from './dto/subscriptions/UserAffiliatePayoutDataDto';
import { UserAffiliateDto } from './dto/userAffiliates/UserAffiliateDto';
import { UserAffiliateApplyRequestDto } from './dto/userAffiliates/UserAffiliateApplyRequestDto';
import { UserAffiliateApplyResponseDto } from './dto/userAffiliates/UserAffiliateApplyResponseDto';

type CentralErrorHandler = (error: unknown) => void;

export class CentralServerClient {
    // #region Private fields
    private readonly _endpointBuilder: ServerEndpointBuilder;
    // private _accessToken: string;
    // #endregion

    // #region Properties
    public get endpointBuilder(): ServerEndpointBuilder {
        return this._endpointBuilder;
    }

    // public get accessToken(): string {
    //     return this._accessToken;
    // }

    // public set accessToken(value: string) {
    //     this._accessToken = value;
    // }

    errorHandler?: CentralErrorHandler;
    // #endregion

    // #region Constructor
    constructor() {
        this._endpointBuilder = new ServerEndpointBuilder(process.env.REACT_APP_BASE_URL_API);
    }
    // #endregion

    // #region Auth endpoints
    async authSignIn(model: SignInRequestDto): Promise<SignInResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.auth_sign_in.toString(), null, null);

            const { data } = await axios.post(url, model);
            console.log("data", data)
            const result: SignInResponseDto = data as SignInResponseDto;

            return result;
        } catch (error) {
            console.log("error", error)
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async authSignUp(model: SignUpRequestDto): Promise<SignUpResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.auth_sign_up.toString(), null, null);

            const { data } = await axios.post(url, model);
            const result: SignInResponseDto = data as SignInResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async authRequestPasswordReset(model: CreatePasswordResetDto): Promise<CreatePasswordResetResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.auth_request_password_reset.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: CreatePasswordResetResponseDto = data as CreatePasswordResetResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async authResetPassword(model: PasswordResetRequestDto): Promise<PasswordResetResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.auth_reset_password.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: PasswordResetResponseDto = data as PasswordResetResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async authAcceptInvitation(model: AcceptInvitationRequestDto): Promise<AcceptInvitationResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.auth_accept_invitation.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: AcceptInvitationResponseDto = data as AcceptInvitationResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Profile endpoints
    async profileChangePassword(model: ChangePasswordRequestDto): Promise<ChangePasswordResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.profile_change_password.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.patch(url, model, headers);
            const result: ChangePasswordResponseDto = data as ChangePasswordResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Communication
    async sendContactForm(model: ContactFormDto): Promise<boolean> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.send_contact_form.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: boolean = data as boolean;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Fund Trading Program
    async getFundTradingProgramList(): Promise<FundTradingProgramDto[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.fund_trading_programs_discount.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);
            const result: FundTradingProgramDto[] = data as FundTradingProgramDto[];

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getFundTradingProgram(fundTradingProgramId: string): Promise<FundTradingProgramDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.fund_trading_programs_id.toString(), [
                new UrlParamPair(CentralServerPathParamKind.fundTradingProgramId, fundTradingProgramId)
            ], null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);
            const result: FundTradingProgramDto = data as FundTradingProgramDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getSignature(moonpayUrl: string): Promise<string> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_get_signature.toString(), null, null);
            const headers = {};

            const { data } = await axios.post(url, { url: moonpayUrl }, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Users
    async getInvitedUserBadges(): Promise<UserBadgeDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.users_invited_badges.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Subscriptions endpoints
    async filterSubscriptions(model: FilterViewUserSubscriptionProgressInfoRequestDto): Promise<FilterViewUserSubscriptionProgressInfoResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_infos_filter_all.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterViewUserSubscriptionProgressInfoResponseDto = data as FilterViewUserSubscriptionProgressInfoResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async filterUserSubscriptions(model: FilterUserSubscriptionRequestDto): Promise<FilterViewUserSubscriptionProgressInfoResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_infos_filter.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: FilterViewUserSubscriptionProgressInfoResponseDto = data as FilterViewUserSubscriptionProgressInfoResponseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async buySubscription(model: CreateUserSubscriptionDto): Promise<UserSubscriptionPurchaseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_buy.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: UserSubscriptionPurchaseDto = data as UserSubscriptionPurchaseDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async activateSubscription(model: ActivateUserSubscriptionDto): Promise<UserSubscriptionDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_activate.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: UserSubscriptionDto = data as UserSubscriptionDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async payoutSubscription(model: PayoutSubscriptionDto): Promise<UserSubscriptionDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_payout.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);
            const result: UserSubscriptionDto = data as UserSubscriptionDto;

            return result;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async cancelUserSubscriptions(userSubscriptionId: string): Promise<boolean> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_cancel.toString(), [
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionId, userSubscriptionId)
            ], null);
            const headers = this.getHeaders();

            const { data } = await axios.patch(url, null, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getUserPayouts(): Promise<FilterPayoutsDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_payouts.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, null, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getUserAffiliate(): Promise<UserAffiliateDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_affiliate_get_data.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getInvitedUsers(): Promise<FilterViewUserAffiliatePurchaseShareResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_affiliate_invited.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, null, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getInvitationCode(): Promise<FilterViewUserAffiliatePurchaseShareResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.users_invitation_code.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getUserAffiliatePayouts(userAffiliatePayoutId?: string): Promise<FilterPayoutsDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_affiliate_payouts.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, { userAffiliatePayoutId }, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async setUserAffiliatePayoutData(model: UserAffiliatePayoutDataDto): Promise<FilterPayoutsDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_affiliate_set_payout_data.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async userAffiliateApply(model: UserAffiliateApplyRequestDto): Promise<UserAffiliateApplyResponseDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_affiliate_apply.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, model, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getUserBillings(): Promise<FilterBillingsDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_billings.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, null, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getCredentials(userSubscriptionId: string): Promise<CredentialsDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_credentials.toString(), [
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionId, userSubscriptionId)
            ], null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getProfileBadge(): Promise<UserBadgeDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.profile_badge.toString(), null, null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getUserSubscriptionStagesData(userSubscriptionId: string): Promise<UserSubscriptionStageIndexDto[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_stages.toString(), [
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionId, userSubscriptionId),
            ], null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getUserSubscriptionStageIndex(userSubscriptionId: string, userSubscriptionStageIndexId: string): Promise<UserSubscriptionStageIndexDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_stage_index.toString(), [
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionId, userSubscriptionId),
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionStageIndexId, userSubscriptionStageIndexId)
            ], null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getUserSubscriptionStageIndexDays(userSubscriptionId: string, userSubscriptionStageIndexId: string): Promise<UserSubscriptionStageIndexDayDto[]> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_stage_index_days.toString(), [
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionId, userSubscriptionId),
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionStageIndexId, userSubscriptionStageIndexId)
            ], null);
            const headers = this.getHeaders();

            const { data } = await axios.get(url, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }

    async getUserSubscriptionStageIndexDayOrders(userSubscriptionId: string, userSubscriptionStageIndexId: string): Promise<FilterUserSubscriptionStageIndexDayOrderDto> {
        try {
            const url = this._endpointBuilder.buildUrl(CentralServerEndpointKind.user_subscriptions_stage_index_day_orders.toString(), [
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionId, userSubscriptionId),
                new UrlParamPair(CentralServerPathParamKind.userSubscriptionStageIndexId, userSubscriptionStageIndexId)
            ], null);
            const headers = this.getHeaders();

            const { data } = await axios.post(url, null, headers);

            return data;
        } catch (error) {
            if (this.errorHandler) {
                this.errorHandler(error);
            }
            else throw error;
        }
    }
    // #endregion

    // #region Private Functions
    private getHeaders(): AxiosRequestConfig {
        const token = AuthCookieManager.getToken();

        const headers: AxiosRequestConfig = {};

        if (token) {
            headers.headers = {
                Authorization: `Bearer ${token}`
            };
        }

        return headers;
    }
    // #endregion
}